import React from "react";
import PropTypes from "prop-types";

const DefaultButton = ({ children, ...p }) => {
  return (
    <button
      {...p}
      className="py-2 px-4 inline-block text-center rounded leading-normal text-gray-100 bg-indigo-600 border border-indigo-600 hover:text-white hover:bg-indigo-700 hover:ring-0 hover:border-indigo-700 focus:bg-indigo-700 focus:border-indigo-700 focus:outline-none focus:ring-0"
    >
      {children}
    </button>
  );
};
DefaultButton.propTypes = {
  children: PropTypes.any.isRequired,
};

export default DefaultButton;
