import { createSlice, createAction } from "@reduxjs/toolkit";

export const fetchUserData = createAction("user/fetch");
export const deleteUser = createAction("user/delete");
export const userAdded = createAction("user/added");
export const userUpdated = createAction("user/updated");

const initialState = {
  formData: {},
  data: [],
  selectedUsers: {},
  deleting: false,
  loading: false,
  error: null,
  success: null,
  total: 0,
  pageCount: 0,
  queryData: {
    filter: {
      userStatus: 1,
    },
    pageIndex: 1,
    pageSize: 10,
  },
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    removeError(state, action) {
      if (action.payload !== undefined) {
        state.success = null;
      } else {
        state.error = null;
      }
    },
    syncFormData(state, action) {
      state.formData = action.payload;
    },
    onFilterSearch(state, action) {
      let filter = action.payload;
      state.queryData = {
        ...state.queryData,
        filter: filter,
        pageIndex: 1,
      };
    },
    pageCountChange(state, action) {
      state.pageCount = action.payload;
    },
    sortByChange(state, action) {
      state.queryData = {
        ...state.queryData,
        sortBy: action.payload,
        pageIndex: 1,
      };
    },
    pageSizeChange(state, action) {
      state.queryData = {
        ...state.queryData,
        pageSize: action.payload,
        pageIndex: 1,
      };
    },
    pageChange(state, action) {
      state.queryData = {
        ...state.queryData,
        pageIndex: action.payload,
      };
    },
    onUserSelection(state, action) {
      state.selectedUsers = action.payload;
    },
    updateRowAfterUpdate(state, action) {
      let newItem = action.payload;
      state.data = state.data.map((item) => {
        if (item.id === newItem.id) {
          return { ...item, ...newItem };
        }
        return item;
      });
    },
    initiateResetPassword(state) {
      state.loading = true;
    },
    resetPasswordSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
    },
    resetPasswordFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    userFetchSuccess(state, action) {
      let { pageSize } = state.queryData;
      let { data, total } = action.payload;
      let pageCount = Math.ceil(total / pageSize);
      state.data = data;
      state.total = total;
      state.pageCount = pageCount;
      state.loading = false;
    },
    userFetchFailed(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    deleteUserSuccess(state, action) {
      let id = action.payload;
      state.data = state.data.filter((item) => item.id !== id);
      state.deleting = false;
    },
    deleteUserPending(state) {
      state.deleting = true;
    },
    deleteUserFailed(state, action) {
      state.error = action.payload;
      state.deleting = false;
    },
    resetUserData(state) {
      state.data = [];
      state.selectedUsers = {};
      state.total = 0;
      state.pageCount = 0;
      state.queryData = {
        filter: {
          userStatus: 1,
        },
        pageIndex: 1,
        pageSize: 10,
      };
    },
  },
});

export const {
  removeError,
  syncFormData,
  onFilterSearch,
  sortByChange,
  pageCountChange,
  pageSizeChange,
  pageChange,
  onUserSelection,
  updateRowAfterUpdate,
  initiateResetPassword,
  resetPasswordFailed,
  resetPasswordSuccess,
  userFetchSuccess,
  userFetchFailed,
  deleteUserFailed,
  deleteUserPending,
  deleteUserSuccess,
  resetUserData,
} = userSlice.actions;

export default userSlice.reducer;
