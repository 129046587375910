import React from "react";
import PropTypes from "prop-types";

const GrayButton = ({ children, ...p }) => {
  return (
    <button
      {...p}
      className="py-2 px-4 inline-block text-center rounded leading-normal text-gray-700 bg-gray-300 hover:bg-gray-100 dark:text-gray-50 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 hover:ring-0 dark:hover:border-gray-800 dark:focus:bg-gray-700 dark:focus:border-gray-800 focus:outline-none focus:ring-0"
    >
      {children}
    </button>
  );
};
GrayButton.propTypes = {
  children: PropTypes.any.isRequired,
};

export default GrayButton;
