import axiosInstance from "../helpers/axiosInstance";

export const usersList = (data = {}) => {
  return axiosInstance.get("/Admin/userList", { params: data });
};

export const getUser = (id) => {
  return axiosInstance.get("/Admin/user", { params: { id } });
};

export const getUserUuid = (uuid) => {
  return axiosInstance.get("/Admin/user", { params: { uuid } });
};

export const addUser = (data) => {
  return axiosInstance.post("/Admin/user", data);
};

export const updateUser = (data) => {
  return axiosInstance.post("/Admin/user", data);
};

export const deleteUser = (id) => {
  return axiosInstance.delete("/Admin/user", { params: { id } });
};

export const resetPassword = (data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ success: "ok" });
    }, 2000);
  });
};

export const unSuspendUser = (userId) => {
  return axiosInstance.post("/Admin/unsuspendUser", { userId, uuid: "" });
};

const UserService = {
  usersList,
  getUser,
  addUser,
  updateUser,
  deleteUser,
  resetPassword,
  getUserUuid,
  unSuspendUser,
};

export default UserService;
