import React from "react";
import Logo from "./Logo";
import Navigation from "./Navigation";

const Sidebar = ({ toggleMenu }) => {
  return (
    <nav
      className="z-50 fixed transition-all duration-500 ease-in-out h-screen bg-gray-200 border-r border-indigo-500 border-opacity-40 dark:border-indigo-500 dark:border-opacity-40 dark:bg-gray-600 shadow-sm w-0 md:w-64 data-[expanded=true]:md:w-0 data-[expanded=true]:w-64"
      data-expanded={toggleMenu}
    >
      <div className="h-full relative overflow-y-auto scrollbars">
        {/*--logo--*/}
        <Logo />
        {/*-- Sidebar menu --*/}
        <Navigation />

        <div className=" absolute bottom-3 left-3 z-50">
          <p className="dark:text-white">
            <span className=" text-2xl">v</span>
            {process.env.REACT_APP_VERSION}
          </p>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
