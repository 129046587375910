import React from "react";
import { CloseIcon } from "../../icons-svg";

const TabDeleteButon = (props) => {
  return (
    <button
      {...props}
      type="button"
      className="disabled:cursor-not-allowed py-2 px-2 inline-block text-center leading-normal text-red-500 bg-transparent focus:outline-none focus:ring-0"
    >
      <CloseIcon className="h-4 w-4" />
    </button>
  );
};

export default TabDeleteButon;
