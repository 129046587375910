import { takeLatest, all, put, call } from "redux-saga/effects";
import {
  initialForgetRequest,
  onForgetFailed,
  onForgetSuccess,
} from "../../features/forget-password/forgetPasswordSlice";
import AuthService from "../../services/auth.service";

function* makeChangePasswordrequest(action) {
  try {
    let data = action.payload;
    yield call(AuthService.forgetPassword, data);
    yield put({ type: onForgetSuccess.toString() });
  } catch (err) {
    let { message, more } = err.error;
    if (more === "login") {
      message = "Either email not registered in our system or user not exist.";
    }
    yield put({ type: onForgetFailed.toString(), payload: message });
  }
}

function* watchForgetRequest() {
  yield takeLatest(initialForgetRequest.toString(), makeChangePasswordrequest);
}

export default function* forgetPasswordSaga() {
  yield all([watchForgetRequest()]);
}
