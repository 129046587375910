import React from "react";
import { SaveIcon } from "../../icons-svg";

const TabSaveButton = (props) => {
  return (
    <button
      {...props}
      className="py-2 px-2 inline-block text-center leading-normal text-green-500 bg-transparent focus:outline-none focus:ring-0"
    >
      <SaveIcon className="h-4 w-4" />
    </button>
  );
};

export default TabSaveButton;
