import { dateToTime } from "./utitity";
import { CryptoJSAesDecrypt } from "./cryp";

//Return access token
export const getAccessToken = () => {
  if (localStorage.getItem("a_token")) {
    return localStorage.getItem("a_token");
  }

  return null;
};

//Return access token
export const getCode = () => {
  if (localStorage.getItem("code")) {
    return localStorage.getItem("code");
  }

  return null;
};

//Check and return service token
export const getServiceToken = () => {
  if (localStorage.getItem("s_token")) {
    return localStorage.getItem("s_token");
  }
  return null;
};
export const setNodeList = (nodeList) => {
  localStorage.setItem("nodeList", JSON.stringify(nodeList));
};
export const getNodeList = () => {
  if (localStorage.getItem("nodeList")) {
    return JSON.parse(localStorage.getItem("nodeList"));
  }
  return null;
};
export const setNode = (node) => {
  localStorage.setItem("node", node);
};
export const getNode = () => {
  if (localStorage.getItem("node")) {
    return localStorage.getItem("node");
  }
  return null;
};

export const setCode = (code) => {
  localStorage.setItem("code", code);
};

/**
 * Save access token in local storage
 *
 * @param {String} token
 */
export const setToken = (token) => {
  localStorage.removeItem("a_token");
  localStorage.setItem("a_token", token);
};

/**
 * Save service token in local storage
 *
 * @param {String} token
 */
export const setServiceToken = (token) => {
  localStorage.removeItem("s_token");
  localStorage.setItem("s_token", token);
};

/**
 * Remove both token from User Pc
 */
export const removeToken = () => {
  localStorage.removeItem("s_token");
  localStorage.removeItem("a_token");
  localStorage.removeItem("code");
  localStorage.removeItem("nodeList");
  localStorage.removeItem("node");
  localStorage.removeItem("mail_token");
  localStorage.removeItem("mail_link");
  localStorage.removeItem("tv_token");
  localStorage.removeItem("tv_link");
};

//Decode access token and get user info from it and decoded value
export const getTokenInfo = () => {
  //later we will get info from saved app jwt
  let data = {
    token: null,
    user: {},
    exp: 0,
    authAppId: null,
  };
  let token = getAccessToken();
  if (token) {
    data = JSON.parse(CryptoJSAesDecrypt(token));
    data.user.conncetionCode = getCode();
    data.token = token;
    data.exp = dateToTime(data.exp);
  }

  return data;
};

//Get only user info from access token
export const getUser = (tokenInfo = null) => {
  //later we will get info from saved app jwt
  if (tokenInfo) {
    return tokenInfo.user;
  }

  let token = getAccessToken();
  if (token) {
    let { user } = JSON.parse(CryptoJSAesDecrypt(token));
    return { ...user, conncetionCode: getCode() };
  }

  return {};
};

export const setTokenForMail = (token) => {
  localStorage.removeItem("mail_token");
  localStorage.setItem("mail_token", token);
};

export const getTokenForMail = () => {
  if (localStorage.getItem("mail_token")) {
    return localStorage.getItem("mail_token");
  }
  return null;
};
export const setLinkForMail = (link) => {
  localStorage.removeItem("mail_link");
  localStorage.setItem("mail_link", link);
};
export const getLinkForMail = () => {
  if (localStorage.getItem("mail_link")) {
    return localStorage.getItem("mail_link");
  }
  return null;
};

export const getTokenForTvDashboard = () => {
  if (localStorage.getItem("tv_token")) {
    return localStorage.getItem("tv_token");
  }
  return null;
};

export const setTokenForTvDashboard = (token) => {
  localStorage.removeItem("tv_token");
  localStorage.setItem("tv_token", token);
};

export const getLinkForTvDashboard = () => {
  if (localStorage.getItem("tv_link")) {
    return localStorage.getItem("tv_link");
  }
  return null;
};

export const setLinkForTvDashboard = (link) => {
  localStorage.removeItem("tv_link");
  localStorage.setItem("tv_link", link);
};

/**
 * Get expire timestamp of access token and compare from the current time plus 10 minutes
 * If expire token have 10 minutes or less to expire then return true other wise false
 * On the basis of true or false application decide to make renew token call to rest api server
 *
 * @param {int} exp
 * @returns {bool}
 */
export const shouldTokenRenew = (exp) => {
  //Check expiry is less than 10 minutes from curren time
  const refreshThreshold = new Date().getTime();
  const diff = exp - refreshThreshold;
  if (diff > 0 && diff < 500000) {
    return true;
  }
  return false;
};

export const isSessionExpired = () => {
  let { exp } = getTokenInfo();
  const currentTime = new Date().getTime();
  if (exp > 0) {
    const diff = exp - currentTime;
    return diff <= 0;
  }
  return true;
};
