import { useEffect, useRef } from "react";
import {
  getAccessToken,
  getCode,
  getLinkForTvDashboard,
  getNode,
  getServiceToken,
  getTokenForTvDashboard,
} from "../../helpers/myStorage";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const EditModel = () => {
  const param = useParams();
  const { mode } = useSelector((state) => state.theme);
  const { code } = param;
  const iframeRef = useRef(null);
  const functionsAndData = {
    code: code,
    link: getLinkForTvDashboard(),
    connectionCode: getCode(),
    token: getAccessToken() + "." + getTokenForTvDashboard(),
    accesToken: getAccessToken(),
    serviceToken: getServiceToken(),
    modelID: localStorage.getItem("modalID"),
    modelUUID: localStorage.getItem("modalUUID"),
    language: localStorage.getItem("language"),
    node: getNode(),
    mode: mode,
  };

  useEffect(() => {
    const channel = new BroadcastChannel("myChannel");
    channel.onmessage = (event) => {
      if (event.data === "new token") {
        iframeRef?.current?.contentWindow.postMessage(
          {
            code: code,
            link: getLinkForTvDashboard(),
            connectionCode: getCode(),
            token: getAccessToken() + "." + getTokenForTvDashboard(),
            accesToken: getAccessToken(),
            serviceToken: getServiceToken(),
            modelID: localStorage.getItem("modalID"),
            modelUUID: localStorage.getItem("modalUUID"),
            language: localStorage.getItem("language"),
            node: getNode(),
            mode: mode,
          },
          "https://admindashboardtest.akead.net/"
        );
      } else if (event.data === "logout") {
        setTimeout(() => {
          iframeRef?.current?.contentWindow.postMessage(
            "logout",
            "https://admindashboardtest.akead.net/"
          );
        }, 1000);
      }
    };

    return () => {
      channel.close();
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      iframeRef?.current?.contentWindow.postMessage(
        functionsAndData,
        "https://admindashboardtest.akead.net/model"
      );
    }, 1000);
  }, [
    code,
    getLinkForTvDashboard(),
    getTokenForTvDashboard(),
    getAccessToken(),
    getCode(),
    localStorage.getItem("modalID"),
    localStorage.getItem("modalUUID"),
    localStorage.getItem("language"),
    mode,
  ]);

  return (
    <iframe
      ref={iframeRef}
      crossOrigin="*"
      id="inlineFrameExample"
      title="basket"
      src="https://admindashboardtest.akead.net/model"
      className="w-full h-screen relative z-50"
      sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
    />
  );
};

export default EditModel;
