import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CiLogin } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { InputText, PasswordInput } from "../../components/inputs";
import Alert from "../../components/alerts/Alert";
import { DefaultButton, IconForButton } from "../../components/buttons";
import {
  loginError,
  removeError,
  loginAsync,
} from "../../features/auth/authSlice";
import NodeModal from "../../components/NodeModal";
import * as MyStorage from "../../helpers/myStorage";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { error, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [credential, setCredential] = useState({
    login: "",
    password: "",
    connectionCode: "PARTNER",
  });
  const onInputChange = (e) => {
    let { name, value, type } = e.target;
    if (type === "checkbox") {
      value = e.target.checked;
    }
    name = name.split("_")[0];
    setCredential((oldData) => ({ ...oldData, [name]: value }));
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    onRemoveError();
    const { login, password, connectionCode } = credential;

    if (!login || !password || !connectionCode) {
      //Handle Error
      dispatch(loginError("All fields are mandatory."));
      return false;
    }
    //Perform Rest API action here
    dispatch(loginAsync(credential));
  };

  const onRemoveError = () => {
    dispatch(removeError());
  };
  return (
    <div className="max-w-full w-full md:w-2/3 lg:w-1/2 px-6 sm:px-12 ">
      {MyStorage.getNodeList()?.length > 0 && <NodeModal />}
      <div className="relative">
        <div className="p-6 sm:py-8 sm:px-12 rounded-lg bg-white dark:bg-gray-900 shadow-xl">
          <form onSubmit={onFormSubmit}>
            <h1 className="text-2xl leading-normal mb-6 font-bold text-gray-800 dark:text-gray-300 text-center">
              Login
            </h1>
            <hr className="block w-12 h-0.5 mx-auto my-5 bg-indigo-500 border-indigo-500" />
            {error && (
              <Alert message={error} type="error" onClose={onRemoveError} />
            )}
            <div className="mb-6">
              <InputText
                name="login_Login"
                placeholder={t("Username")}
                value={credential.login}
                onChange={onInputChange}
                type="text"
                required
                disabled={loading}
              />
            </div>
            <div className="mb-6">
              <PasswordInput
                name="password_Login"
                placeholder={t("Password")}
                value={credential.password}
                onChange={onInputChange}
                required
                disabled={loading}
              />
            </div>
            {/* <InputText
              name="connectionCode_Login"
              placeholder={t("Connection Code")}
              value={credential.connectionCode}
              onChange={onInputChange}
              type="text"
              required
              disabled={loading}
            /> */}
            <div className="grid mt-4">
              <DefaultButton type="submit" disabled={loading}>
                <IconForButton>
                  <CiLogin />
                </IconForButton>
                {loading ? t("processing") : t("Login")}
              </DefaultButton>
            </div>
          </form>
          <div className="mt-6">
            <p className="text-center">
              <Link
                className="hover:text-indigo-500 hover:underline"
                to="/authentication/forget-password"
              >
                {t("Lost password")}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
