import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BsKey } from "react-icons/bs";
import { InputText } from "../../components/inputs";
import { DefaultButton, IconForButton } from "../../components/buttons";
import Alert from "../../components/alerts/Alert";
import TimerAlert from "../../components/alerts/TimerAlert";
import {
  removeMessage,
  initialForgetRequest,
  onForgetFailed,
} from "../../features/forget-password/forgetPasswordSlice";

const ForgetPassword = () => {
  const { error, success } = useSelector((state) => state.forgetPassword);
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  const onFormSubmit = (e) => {
    e.preventDefault();
    onRemoveError();
    if (!email) {
      //Handle Error
      dispatch(onForgetFailed("All fields are mandatory."));
      return false;
    }
    //Perform Rest API action here
    dispatch(initialForgetRequest({ email }));
  };

  const onRemoveError = (isSuccess = null) => {
    dispatch(removeMessage(isSuccess));
  };

  return (
    <div className="max-w-full w-full md:w-2/3 lg:w-1/2 px-6 sm:px-12">
      <div className="relative">
        <div className="p-6 sm:py-8 sm:px-12 rounded-lg bg-white dark:bg-gray-800 shadow-xl">
          <form onSubmit={onFormSubmit}>
            <h1 className="text-2xl leading-normal mb-6 font-bold text-gray-800  dark:text-gray-300 text-center">
              Reset Password
            </h1>
            <hr className="block w-12 h-0.5 mx-auto my-5 bg-gray-700 border-gray-700" />
            <p className="text-gray-500 mb-6">
              Enter your email address and we'll send you an email with
              instructions to reset your password.
            </p>
            {error && (
              <Alert message={error} type="error" onClose={onRemoveError} />
            )}
            {success && (
              <TimerAlert
                message={error}
                type="success"
                delay={3000}
                fallbackFn={() => onRemoveError("success")}
              />
            )}
            <div className="mb-6">
              <InputText
                name="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                aria-label="email"
                type="email"
                required=""
              />
            </div>
            <div className="grid">
              <DefaultButton type="submit">
                <IconForButton>
                  <BsKey />
                </IconForButton>
                Reset Password
              </DefaultButton>
            </div>
          </form>
          <div className="mt-6">
            <p className="text-center">
              <Link
                className="hover:text-indigo-500 hover:underline"
                to="/authentication"
              >
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
