import React, { useState } from "react";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import WhiteInputText from "./WhiteInputText";

const WhitePasswordInput = (props) => {
  const [open, setOpen] = useState(false);

  const onEyeClick = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <div className="relative">
      <WhiteInputText
        {...props}
        type={open ? "text" : "password"}
        autoComplete="off"
      />
      <span
        className="absolute right-2 top-2.5 cursor-pointer"
        onClick={onEyeClick}
      >
        {open ? (
          <BsEye className="h-4 w-4" />
        ) : (
          <BsEyeSlash className="h-4 w-4" />
        )}
      </span>
    </div>
  );
};

export default WhitePasswordInput;
