import { takeLatest, all, put, call, select } from "redux-saga/effects";
import UserService from "../../services/user.service";
import {
  fetchUserData,
  deleteUser,
  userFetchFailed,
  userFetchSuccess,
  deleteUserFailed,
  deleteUserSuccess,
  resetPasswordFailed,
  resetPasswordSuccess,
  initiateResetPassword,
  userAdded,
  userUpdated,
} from "../../features/users/userSlice";

import { error, success } from "../../features/toast/toastSlice";
import { SUCCESS_PASSWORD_RESET_MAIL, DELETED } from "../../data/message";

//Helper function to generate Query Array for Request call
const handleQueryData = (queryData) => {
  let { pageIndex, pageSize, filter } = queryData;
  let offset = (pageIndex - 1) * pageSize;
  let queryString = {};
  queryString["limit"] = pageSize;
  queryString["offset"] = offset;
  for (let key in filter) {
    if (filter[key]) {
      queryString[key] = filter[key];
    }
  }
  return {
    ...queryString,
  };
};

/**
 * Make Rest API call to get conncetion serach data
 * On Success store connection data in connection redux state
 * On Failed update conncetion redux error state to show error on the Listing Page
 *
 * @param {Object} action
 */
function* fetchUsers(action) {
  try {
    let postData = handleQueryData(action.payload);
    const { userList, paging } = yield call(UserService.usersList, postData);
    let { totalResults, resultCount } = paging;
    if (userList === null) {
      yield put({
        type: userFetchSuccess.toString(),
        payload: { data: [], total: totalResults, resultCount },
      });
      return;
    }
    yield put({
      type: userFetchSuccess.toString(),
      payload: { data: userList, total: totalResults, resultCount },
    });
  } catch (err) {
    let { message } = err;
    yield put({ type: userFetchFailed.toString(), payload: { message } });
  }
}

/**
 * Here we initiate a Rest API call to delete connection
 * On success or Failed we update connection redux data
 *
 * @param {Object} action
 */
function* deleteUserData(action) {
  try {
    let id = action.payload;
    yield call(UserService.deleteUser, id);
    reInitiateUserList();
    yield put({
      type: success.toString(),
      payload: { message: DELETED, delay: 5000 },
    });
    yield put({ type: deleteUserSuccess.toString(), payload: id });
  } catch (err) {
    let { message } = err;
    if (message === "Database Error") {
      message = "Some error occured.";
    }
    yield put({ type: error.toString(), payload: { message, delay: 3000 } });
    yield put({ type: deleteUserFailed.toString(), payload: message });
  }
}

function* reInitiateUserList() {
  const queryData = yield select((state) => state.user.queryData);
  yield put({ type: fetchUserData.toString(), payload: queryData });
}

/**
 * Make Rest API call to send reset password mail
 *
 * @param {Object} action
 */
function* makeResetPasswordRequest(action) {
  try {
    let { data, selectedUsers } = yield select((state) => state.user);
    let ids = Object.keys(selectedUsers);
    let currentId = parseInt(ids[0]);
    let { email } = data.find((c) => c.id === currentId);

    yield call(UserService.resetPassword, { email });
    yield put({
      type: success.toString(),
      payload: { message: SUCCESS_PASSWORD_RESET_MAIL, delay: 3000 },
    });
    yield put({
      type: resetPasswordSuccess.toString(),
      payload: SUCCESS_PASSWORD_RESET_MAIL,
    });
  } catch (err) {
    let { message } = err;
    yield put({ type: error.toString(), payload: { message, delay: 3000 } });
    yield put({ type: resetPasswordFailed.toString(), payload: message });
  }
}

//Listener for Connection List request
function* watchListRequest() {
  yield takeLatest(fetchUserData.toString(), fetchUsers);
}

//Listener for deleteConnection request
function* watchDeleteRequest() {
  yield takeLatest(deleteUser.toString(), deleteUserData);
}

//Listener for Password reset mail request
function* watchResetPasswordRequest() {
  yield takeLatest(initiateResetPassword.toString(), makeResetPasswordRequest);
}

//Listener for deleteConnection request
function* watchUserUpdated() {
  yield takeLatest(userUpdated.toString(), reInitiateUserList);
}

//Listener for deleteConnection request
function* watchUserAdded() {
  yield takeLatest(userAdded.toString(), reInitiateUserList);
}

export default function* userSearchSaga() {
  yield all([
    watchListRequest(),
    watchDeleteRequest(),
    watchResetPasswordRequest(),
    watchUserAdded(),
    watchUserUpdated(),
  ]);
}
