import React from "react";
import { useTranslation } from "react-i18next";

const SuspenseLoader = () => {
  const { t } = useTranslation();

  return (
    <div
      data-show={true}
      className="hidden transition-[all 1s ease-in-out] data-[show=true]:block data-[show=true]:z-[60] fixed inset-0 bg-gray-300 bg-opacity-70 dark:bg-gray-800 dark:bg-opacity-70"
    >
      <div className="absolute left-1/2 top-1/2 transform -translate-y-1/2">
        <div className="relative mx-auto my-12">
          <div className="inline-block">
            <span className="dark:text-gray-300 text-gray-400 -ml-12">
              {t(`Loading...`)}
            </span>
            <svg
              className="animate-spin h-8 w-8 text-indigo-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuspenseLoader;
