import React from "react";
import CheckboxWithLabel from "./CheckboxWithLabel";
import InputError from "../InputError";
import Sup from "../Sup";

const CheckboxInputWithLableAndError = (props) => {
  const { title, label, name, error, ...p } = props;
  return (
    <div className="form-group flex-shrink max-w-full px-4 w-full md:w-1/2 mb-2">
      <label htmlFor={name} className="inline-block mb-2">
        {title} {props.required ? <Sup /> : null}
      </label>
      <CheckboxWithLabel {...p} name={name} title={label} />
      <InputError>{error}</InputError>
    </div>
  );
};

export default CheckboxInputWithLableAndError;
