import { axiosPublicInstance } from "../helpers/axiosInstance";

export const getServiceList = (data = {}) => {
  return axiosPublicInstance
    .get("/Membership/serviceList", { params: data })
    .then((res) => {
      return res;
    });
};
const ServiceService = {
  getServiceList,
};

export default ServiceService;
