import CryptoJS from "crypto-js";

const CHIPER_KEY = process.env.REACT_APP_CHIPER_KEY;

/**
 * Used to create signature for the data send to rest api
 * @param {*} signatureString
 * @returns signature
 */
export const CryptoJSAesEncrypt = (signatureString) => {
  let randomByteWord = CryptoJS.lib.WordArray.random(16);
  let base64EncodeString = CryptoJS.enc.Base64.stringify(randomByteWord);
  let afterReplaceString = base64EncodeString.replace(/\+|=|\//g, "");
  var iv = afterReplaceString.substr(0, 16);
  let pos = CHIPER_KEY.length > 16 ? 16 : CHIPER_KEY.length;
  let signatureString256HashToString = CryptoJS.enc.Latin1.stringify(
    CryptoJS.SHA256(signatureString),
  );
  let key64String256HashString = CryptoJS.enc.Latin1.stringify(
    CryptoJS.SHA256(CHIPER_KEY),
  ).substr(0, 64);
  let aes = CryptoJS.AES.encrypt(
    CryptoJS.enc.Latin1.parse(signatureString256HashToString),
    CryptoJS.enc.Latin1.parse(key64String256HashString),
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
    },
  );
  var aesString = CryptoJS.enc.Latin1.stringify(
    CryptoJS.enc.Base64.parse(aes.toString()),
  );
  let combineString = iv.substr(0, pos) + aesString + iv.substr(pos, 16 - pos);
  let base64String = CryptoJS.enc.Latin1.parse(combineString).toString(
    CryptoJS.enc.Base64,
  );
  let signature = strtr(base64String);
  return signature;
};

/**
 * Decode JWT token and get payload like user info and expire time
 * @param {*} encryptedString
 * @returns {*} data
 */
export const CryptoJSAesDecrypt = (encryptedString) => {
  encryptedString = strtr(encryptedString, "decode");
  encryptedString = CryptoJS.enc.Base64.parse(encryptedString).toString(
    CryptoJS.enc.Latin1,
  );
  let pos = CHIPER_KEY.length > 16 ? 16 : CHIPER_KEY.length;
  let key64String256HashString = CryptoJS.enc.Latin1.stringify(
    CryptoJS.SHA256(CHIPER_KEY),
  ).substr(0, 64);
  let iv =
    encryptedString.substr(0, pos) +
    encryptedString.substr(encryptedString.length - (16 - pos), 16 - pos);
  let aes = encryptedString.substring(pos, encryptedString.length - pos);
  let decrypted = CryptoJS.AES.decrypt(
    CryptoJS.enc.Latin1.parse(aes).toString(CryptoJS.enc.Base64),
    CryptoJS.enc.Latin1.parse(key64String256HashString),
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
    },
  );
  let data = decrypted.toString(CryptoJS.enc.Utf8);
  return data;
};
export const AkdCrypt256b64 = (signatureString) => {
  let randomByteWord = CryptoJS.lib.WordArray.random(16);
  let base64EncodeString = CryptoJS.enc.Base64.stringify(randomByteWord);
  let afterReplaceString = base64EncodeString.replace(/\+|=|\//g, "");
  var iv = afterReplaceString.substr(0, 16);
  let pos = CHIPER_KEY.length > 16 ? 16 : CHIPER_KEY.length;
  let key64String256HashString = CryptoJS.enc.Latin1.stringify(
    CryptoJS.SHA256(CHIPER_KEY),
  ).substr(0, 64);
  let aes = CryptoJS.AES.encrypt(
    signatureString,
    CryptoJS.enc.Latin1.parse(key64String256HashString),
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
    },
  );
  var aesString = CryptoJS.enc.Latin1.stringify(
    CryptoJS.enc.Base64.parse(aes.toString()),
  );
  let combineString = iv.substr(0, pos) + aesString + iv.substr(pos, 16 - pos);
  let base64String = CryptoJS.enc.Latin1.parse(combineString).toString(
    CryptoJS.enc.Base64,
  );
  let signature = strtr(base64String);
  return signature;
};

/**
 * This function is used to random hash srtring as device id
 * @returns deviceId
 */
export const getDeviceId = () => {
  let deviceId = CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex);
  return deviceId;
};

/**
 * Function is used to replace and revert on the basis of encryption type
 * @param {string} str
 * @param {string} type
 * @returns {string}
 */
export const strtr = (str, type = "encode") => {
  if (type === "encode") {
    str = str.replace(/\+/g, "-");
    str = str.replace(/\//g, "_");
  } else {
    str = str.replace(/-/g, "+");
    str = str.replace(/_/g, "/");
  }
  return str;
};
