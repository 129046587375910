import { takeLatest, all, put, call, select } from "redux-saga/effects";
import ConnectionService from "../../services/connection.service";
import {
  connectioInitiate,
  connectionAdded,
  connectionFailed,
  connectionSuccess,
  connectionUpdate,
  deleteFailed,
  deleteInitiate,
  deleteSuccess,
} from "../../features/connection/connectionSlice";
import { error, success } from "../../features/toast/toastSlice";
import { DELETED } from "../../data/message";

//Helper function to generate Query Array for Request call
const handleQueryData = (queryData) => {
  let { pageIndex, pageSize, filter } = queryData;
  let offset = (pageIndex - 1) * pageSize;
  let queryString = {};
  queryString["limit"] = pageSize;
  queryString["offset"] = offset;
  for (let key in filter) {
    if (filter[key]) {
      queryString[key] = filter[key];
    }
  }
  return queryString;
};

/**
 * Make Rest API call to get conncetion serach data
 * On Success store connection data in connection redux state
 * On Failed update conncetion redux error state to show error on the Listing Page
 *
 * @param {Object} action
 */
function* fetchConnections(action) {
  try {
    let postData = handleQueryData(action.payload);
    const { connectionList, paging } = yield call(
      ConnectionService.connectionsList,
      postData,
    );
    let { totalResults, resultCount } = paging;
    if (connectionList) {
      yield put({
        type: connectionSuccess.toString(),
        payload: { data: connectionList, total: totalResults, resultCount },
      });
    } else {
      yield put({
        type: connectionSuccess.toString(),
        payload: { data: [], total: 0, resultCount: 0 },
      });
    }
  } catch (err) {
    let { message } = err;
    yield put({ type: connectionFailed.toString(), payload: message });
  }
}

/**
 * Here we initiate a Rest API call to delete connection
 * On success or Failed we update connection redux data
 *
 * @param {Object} action
 */
function* deleteConncetion(action) {
  try {
    let id = action.payload;
    yield call(ConnectionService.deleteConnection, id);
    yield put({
      type: success.toString(),
      payload: { message: DELETED, delay: 5000 },
    });
    yield put({ type: deleteSuccess.toString(), payload: id });
    yield call(reInitiateConnection);
  } catch (err) {
    let { message } = err;
    if (message === "Database Error") {
      message = "Some error occured.";
    }
    yield put({ type: error.toString(), payload: { message, delay: 5000 } });
    yield put({ type: deleteFailed.toString(), payload: message });
  }
}

function* reInitiateConnection() {
  const queryData = yield select((state) => state.connection.queryData);
  yield put({ type: connectioInitiate.toString(), payload: queryData });
}

//Listener for Connection List request
function* watchListRequest() {
  yield takeLatest(connectioInitiate.toString(), fetchConnections);
}

//Listener for deleteConnection request
function* watchDeleteRequest() {
  yield takeLatest(deleteInitiate.toString(), deleteConncetion);
}

//Listener for deleteConnection request
function* watchConnectionUpdated() {
  yield takeLatest(connectionUpdate.toString(), reInitiateConnection);
}

//Listener for deleteConnection request
function* watchConnectionAdded() {
  yield takeLatest(connectionAdded.toString(), reInitiateConnection);
}

export default function* connectionSaga() {
  yield all([
    watchListRequest(),
    watchDeleteRequest(),
    watchConnectionAdded(),
    watchConnectionUpdated(),
  ]);
}
