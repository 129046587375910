import { createSlice, createAction } from "@reduxjs/toolkit";

const initialState = {
  tabList: [],
  selectedTab: 0,
  lastSelecetTab: 0,
};

const tabSlice = createSlice({
  name: "tab",
  initialState,
  reducers: {
    /**
     * Get tab data from payload
     * Check if tab already in opened tab list then activate the tab
     * If not present in opened tag then open new tab
     */
    openTab(state, action) {
      let lastActiveTab = state.selectedTab;
      let currenttabList = [...state.tabList];
      let tab = action.payload;
      let selectedTab = tab.id;
      if (lastActiveTab !== tab.id) {
        if (currenttabList.find((item) => item.id === tab.id)) {
        } else {
          currenttabList.push(tab);
          state.tabList = currenttabList;
        }
        state.selectedTab = selectedTab;
        state.lastSelecetTab = lastActiveTab;
      }
    },

    activateTab(state, action) {
      let currentTab = state.selectedTab;
      let payLoadTab = action.payload;
      if (currentTab !== payLoadTab) {
        state.lastSelecetTab = currentTab;
        state.selectedTab = payLoadTab;
      }
    },

    /**
     * Remove Tab from the opened tab list
     */
    removeTab(state, action) {
      let leftTabs = [
        ...state.tabList.filter((item) => item.id !== action.payload),
      ];
      let lastActiveTab = state.lastSelecetTab;
      if (
        leftTabs.length &&
        !leftTabs.find((tab) => tab.id === lastActiveTab)
      ) {
        lastActiveTab = leftTabs[0].id;
      }
      if (!leftTabs.length) {
        lastActiveTab = 0;
      }
      state.selectedTab = lastActiveTab;
      state.tabList = leftTabs;
    },
  },
});

export const refreshTab = createAction("refreshTab");

export const { openTab, removeTab, activateTab } = tabSlice.actions;
export default tabSlice.reducer;
