import ArrowDownIcon from "../icons-svg/ArrowDownIcon";

const CaretIcon = ({ show, isOpen }) => {
  if (!show) {
    return null;
  }
  return (
    <span className="inline-block ltr:float-left rtl:float-right ltr:mr-3 rtl:ml-3 mt-1">
      <ArrowDownIcon
        data-open={isOpen}
        className="h-3 w-3 text-gray-400 dark:text-gray-100 ltr:-rotate-90 rtl:rotate-90 data-[open=true]:ltr:rotate-0"
      />
    </span>
  );
};

export default CaretIcon;
