import { axiosInstanceForTvDashboard } from "../helpers/axiosInstance";
import * as MyStorage from "../helpers/myStorage";

export const getModelList = (data = {}) => {
  return axiosInstanceForTvDashboard
    .get(MyStorage.getLinkForTvDashboard() + "/admin/modelList", {
      params: data,
    })
    .then((res) => {
      return res;
    });
};

export const deleteModel = (uuid) => {
  return axiosInstanceForTvDashboard
    .delete(MyStorage.getLinkForTvDashboard() + "/admin/model", {
      params: { uuid },
    })
    .then((res) => {
      return res;
    });
};

export const postModel = (data = {}) => {
  return axiosInstanceForTvDashboard
    .post(MyStorage.getLinkForTvDashboard() + "/admin/model", data)
    .then((res) => {
      return res;
    });
};

const TvDashboardService = {
  getModelList,
  deleteModel,
  postModel,
};

export default TvDashboardService;
