export const SUCCESS_PASSWORD_RESET_MAIL =
  "Successfully send password reset mail.";
export const INITIATE_PASSWORD_RESET_MAIL =
  "Please wait. sending password reset mail...";
export const COMMON_PROCESSING_MESSAGE = "Please wait. processing...";
export const COMMON_FETCHING_MESSAGE = "Please wait. fetching...";
export const APP_INITIALIZE_MESSAGE = "Initializing...";
export const DELETE_MESSAGE = "Please wait. deleting...";
export const MANDATORY_ERROR = "*Mandatory Fields can not be empty";
export const ATLEAST_NODE = "Please provide atleast one node";
export const CONNECTION_CREATED = "Conncetion Successfully created";
export const CONNECTION_UPDATED = "Conncetion Successfully updated";
export const SUCCESS_CREATED = "Successfully created";
export const SUCCESS_UPDATED = "Successfully created";
export const DELETED = "Successfully Deleted";
export const MODEL_CREATED = "Model Successfully created";
