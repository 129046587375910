import React from "react";
import { BsFillEnvelopeFill } from "react-icons/bs";

const TabMailButton = (props) => {
  return (
    <button
      {...props}
      type="button"
      className="disabled:cursor-not-allowed py-1 px-2 inline-block text-center  leading-normal text-orange-500 bg-transparent focus:outline-none focus:ring-0"
    >
      <BsFillEnvelopeFill className="h-5 w-5" />
    </button>
  );
};

export default TabMailButton;
