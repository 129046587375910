import FullScreenIcon from "./full-screen.svg";
import DarkLightIcon from "./light-dark-mode.svg";
import SaveIconSvg from "./save.svg";
import EditIconSvg from "./edit.svg";
import SwitchIconSvg from "./off.svg";
import StatusIconSvg from "./status.svg";
import CloseIconSvg from "./delete.svg";
import CancelIconSvg from "./cancel.svg";
import ClickIconSvg from "./click.svg";
import LogoIconSvg from "./logo.png";
import LeftArrowIconSvg from "./left-arrow.svg";
import LeftDoubleArrowIconSvg from "./left-double-arrow.svg";
import RightArrowIconSvg from "./right-arrow.svg";
import RightDoubleArrowIconSvg from "./right-double-arrow.svg";
import DownArrowIconSvg from "./down-arrow.svg";
import DownArrowTwoIconSvg from "./down-arrow_2.svg";

export const LeftArrowIcon = (props) => {
  return <img {...props} src={LeftArrowIconSvg} alt="Full Screen" />;
};

export const LeftDoubleArrowIcon = (props) => {
  return <img {...props} src={LeftDoubleArrowIconSvg} alt="Full Screen" />;
};

export const RightArrowIcon = (props) => {
  return <img {...props} src={RightArrowIconSvg} alt="Full Screen" />;
};

export const RightDoubleArrowIcon = (props) => {
  return <img {...props} src={RightDoubleArrowIconSvg} alt="Full Screen" />;
};

export const DownArrowIcon = (props) => {
  return <img {...props} src={DownArrowIconSvg} alt="Full Screen" />;
};

export const DownArrowTwoIcon = (props) => {
  return <img {...props} src={DownArrowTwoIconSvg} alt="Full Screen" />;
};

export const FullScreen = (props) => {
  return <img {...props} src={FullScreenIcon} alt="Full Screen" />;
};

export const DarkLight = (props) => {
  return <img {...props} src={DarkLightIcon} alt="Full Screen" />;
};

export const SaveIcon = (props) => {
  return <img {...props} src={SaveIconSvg} alt="Full Screen" />;
};

export const EditIcon = (props) => {
  return <img {...props} src={EditIconSvg} alt="Full Screen" />;
};

export const SwitchIcon = (props) => {
  return <img {...props} src={SwitchIconSvg} alt="Full Screen" />;
};

export const StatusIcon = (props) => {
  return <img {...props} src={StatusIconSvg} alt="Full Screen" />;
};

export const CloseIcon = (props) => {
  return <img {...props} src={CloseIconSvg} alt="Full Screen" />;
};

export const ResetIcon = (props) => {
  return <img {...props} src={CancelIconSvg} alt="Full Screen" />;
};

export const ClickIcon = (props) => {
  return <img {...props} src={ClickIconSvg} alt="Full Screen" />;
};

export const LogoIcon = (props) => {
  return <img {...props} src={LogoIconSvg} alt="AKEAD" />;
};
