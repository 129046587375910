import React, { useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import PropTypes from "prop-types";

const Search = ({ onSearch, ...p }) => {
  const delay = useRef(null);
  const [search, setSearch] = useState("");

  const onInputChange = (e) => {
    if (delay.current) {
      clearTimeout(delay.current);
    }
    setSearch(e.target.value);
    delay.current = setTimeout(() => {
      onSearch(e.target.value);
    }, 500);
  };

  const clearSerach = (e) => {
    e.preventDefault();
    setSearch("");
    onSearch("");
  };

  return (
    <div className="flex flex-wrap items-stretch w-full relative">
      <div className="absolute z-10 top-2 text-gray-400 left-2">
        <FaSearch />
      </div>
      <input
        {...p}
        onChange={onInputChange}
        value={search}
        type="text"
        className="flex-shrink flex-grow max-w-full leading-5 relative text-sm py-1 px-4 pl-8 rounded-md text-gray-800 bg-gray-100 overflow-x-auto focus:outline-none border border-gray-100 focus:border-gray-200 focus:ring-0 dark:text-gray-400 dark:bg-gray-500 dark:border-gray-500 dark:focus:border-gray-600"
        aria-label="Search"
      />
      {search && (
        <div
          onClick={clearSerach}
          className="absolute cursor-pointer top-2 text-gray-400 right-2"
        >
          <MdOutlineClose />
        </div>
      )}
    </div>
  );
};

Search.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default Search;
