import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import SessionExpired from "../../components/SessionExpired";
import { useEffect } from "react";

const MainLayout = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { open } = useSelector((state) => state.menu);
  const { loading } = useSelector((state) => state.service);
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/authentication" replace={true} />;
  }
  return (
    <>
      <div className="wrapper overflow-x-hidden bg-white dark:bg-black h-full">
        {!location.pathname.includes("/tvDashboard") && (
          <Sidebar toggleMenu={open} />
        )}

        <div
          className={
            "flex flex-col min-h-screen transition-all duration-500 ease-in-out ltr:ml-0 ltr:mr-0 md:ltr:ml-64 rtl:mr-0 rtl:ml-0 md:rtl:mr-64 aria-expanded:ltr:ml-64 aria-expanded:rtl:mr-64 aria-expanded:ltr:-mr-64 aria-expanded:md:ltr:ml-0 aria-expanded:md:ltr:mr-0 aria-expanded:rtl:-ml-64 aria-expanded:md:rtl:mr-0 aria-expanded:md:rtl:ml-0 h-full"
          }
          style={
            location.pathname.includes("/tvDashboard")
              ? { marginLeft: "0px", marginRight: "0px" }
              : {}
          }
          aria-expanded={open}
        >
          {!location.pathname.includes("/tvDashboard") && (
            <Header toggleMenu={open} />
          )}
          <main className=" h-full overflow-hidden mb-1">
            <div className="mx-auto h-full">
              <div className="flex flex-wrap flex-row h-full">
                <div className="flex-shrink max-w-full w-full h-full ">
                  {loading ? "initializing...." : <Outlet />}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <SessionExpired />
    </>
  );
};

export default MainLayout;
