import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modal: "",
  modalData: "",
};

const modalSlice = createSlice({
  name: "portal",
  initialState,
  reducers: {
    openModal(state, action) {
      let name = action.payload?.name || action.payload;
      let data = action.payload?.modalData || "";
      state.modal = name;
      state.modalData = data;
    },
    closeModal(state) {
      state.modal = "";
      state.modalData = "";
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
