import { createSlice } from "@reduxjs/toolkit";

export const themeMode = Object.freeze({
  LIGHT: "light",
  DARK: "dark",
});

const initialState = {
  mode:
    localStorage.getItem("thememode") &&
    Object.values(themeMode).includes(localStorage.getItem("thememode"))
      ? localStorage.getItem("thememode")
      : themeMode.LIGHT,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleMode(state) {
      state.mode =
        state.mode === themeMode.LIGHT ? themeMode.DARK : themeMode.LIGHT;
    },
  },
});

export const { toggleMode } = themeSlice.actions;

export default themeSlice.reducer;
