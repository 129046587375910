import ResetIcon from "../../icons-svg/ResetIcon";

const TabResetButton = (props) => {
  return (
    <button
      {...props}
      title="Reset"
      className="py-2 px-2 inline-block text-center leading-normal bg-transparent focus:outline-none focus:ring-05"
    >
      <ResetIcon className="h-4 w-4 text-yellow-600" />
    </button>
  );
};

export default TabResetButton;
