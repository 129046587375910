import { takeLatest, put, all, select } from "redux-saga/effects";
import { refreshTab, removeTab, openTab } from "../../features/tabs/tabSlice";

/**
 * We get payload of `refreshTab` action
 * First we delete opened or current connection tab
 * Lastly open same tab with new data
 *
 * @param {Object} action
 */
function* openOrRefreshTab(action) {
  let tab = action.payload;
  let tabList = yield select((state) => state.tab.tabList);
  let isExist = yield tabList.find((t) => t.id === tab.id);
  if (isExist) {
    yield put({ type: removeTab.toString(), payload: tab.id });
  }
  yield put({ type: openTab.toString(), payload: tab });
}

/**
 * Here we have watch on the action `refershTab`
 */
function* watchRefreshTab() {
  yield takeLatest(refreshTab.toString(), openOrRefreshTab);
}

export default function* tabSaga() {
  yield all([watchRefreshTab()]);
}
