import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Alert = ({ message, type, onClose }) => {
  const { t } = useTranslation();
  if (message === "Cannot read properties of undefined (reading 'nodeList')") {
    message = "you have no any node";
  }
  return (
    <div
      data-type={type}
      className="flex justify-between items-center relative data-[type=warning]:bg-yellow-100 data-[type=warning]:text-yellow-900 data-[type=info]:bg-blue-200 data-[type=blue]:text-blue-900 data-[type=error]:bg-red-100 data-[type=error]:text-red-900 data-[type=success]:bg-green-100 data-[type=success]:text-green-900 py-3 px-6 rounded mb-4"
    >
      {t(message)}
      <button
        type="button"
        onClick={() => {
          onClose();
        }}
      >
        <span className="text-2xl">×</span>
      </button>
    </div>
  );
};

Alert.defaultProps = {
  type: "error",
};

Alert.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Alert;
