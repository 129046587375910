import React from "react";
import NavItem from "./NavItem";

const NavList = ({ navList, className, ...p }) => {
  return (
    <ul className={className}>
      {navList.map((item, index) => (
        <NavItem item={item} key={index} {...p} />
      ))}
    </ul>
  );
};

export default NavList;
