import React from "react";
import PropTypes from "prop-types";
import CheckboxInput from "./CheckboxInput";

const CheckboxWithLabel = ({ title, name, className, ...p }) => {
  return (
    <div className={className}>
      <CheckboxInput {...p} name={name} />
      <label className="ltr:ml-2 rtl:mr-2" htmlFor={name}>
        {title}
      </label>
    </div>
  );
};

CheckboxWithLabel.defaultProps = {
  title: "Active",
  name: "MyCheckbox",
  className: "",
};

CheckboxWithLabel.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckboxWithLabel;
