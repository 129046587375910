import { takeLatest, all, put, call, select } from "redux-saga/effects";
import TvDashboardService from "../../services/tvDashbord.service";
import {
  TvDasboardAdded,
  TvDasboardDeleteFailed,
  TvDasboardDeleteInitiate,
  TvDasboardDeleteSuccess,
  TvDasboardFailed,
  TvDasboardInitiate,
  TvDasboardSuccess,
} from "../../features/TvDasboard/tvDashboardSlice";
import { success } from "../../features/toast/toastSlice";
import { DELETED } from "../../data/message";

const handleQueryData = (queryData) => {
  let { pageIndex, pageSize, filter } = queryData;
  let offset = (pageIndex - 1) * pageSize;
  let queryString = {};
  queryString["limit"] = pageSize;
  queryString["offset"] = offset;
  queryString["language"] = "en";
  for (let key in filter) {
    if (filter[key]) {
      queryString[key] = filter[key];
    }
  }
  return queryString;
};

/**
 * Make Rest API call to get Modals serach data
 * On Success store list data in list redux state
 * On Failed update list redux error state to show error on the Listing Page
 *
 * @param {Object} action
 */

function* fetcTvDasboardModals(action) {
  try {
    let postData = handleQueryData(action.payload);
    const { modelList, paging } = yield call(
      TvDashboardService.getModelList,
      postData
    );
    let { totalResults, resultCount } = paging;
    if (modelList.length > 0) {
      yield put({
        type: TvDasboardSuccess.toString(),
        payload: {
          data: modelList,
          total: totalResults,
          resultCount: resultCount,
        },
      });
    } else {
      yield put({
        type: TvDasboardSuccess.toString(),
        payload: { data: [], total: 0, resultCount: 0 },
      });
    }
  } catch (err) {
    let { message } = err;
    yield put({ type: TvDasboardFailed.toString(), payload: message });
  }
}

/**
 * Here we initiate a Rest API call to delete model
 * On success or Failed we update model redux data
 *
 * @param {Object} action
 */
function* deleteModel(action) {
  try {
    let uuid = action.payload;
    yield call(TvDashboardService.deleteModel, uuid);
    yield put({
      type: success.toString(),
      payload: { message: DELETED, delay: 5000 },
    });
    yield put({ type: TvDasboardDeleteSuccess.toString(), payload: uuid });
    yield call(reInitiateModelList);
  } catch (err) {
    let { message } = err;
    if (message === "Database Error") {
      message = "Some error occured.";
    }
    yield put({
      type: TvDasboardFailed.toString(),
      payload: {
        message,
        delay: 5000,
      },
    });
    yield put({ type: TvDasboardDeleteFailed.toString(), payload: message });
  }
}

function* reInitiateModelList() {
  const queryData = yield select((state) => state.tvDashboard.queryData);
  yield put({ type: TvDasboardInitiate.toString(), payload: queryData });
}

function* watchListRequest() {
  yield takeLatest(TvDasboardInitiate.toString(), fetcTvDasboardModals);
}
function* watchDeleteRequest() {
  yield takeLatest(TvDasboardDeleteInitiate.toString(), deleteModel);
}

function* watchConnectionAdded() {
  yield takeLatest(TvDasboardAdded.toString(), reInitiateModelList);
}

export default function* tvDashboardSaga() {
  yield all([watchListRequest(), watchDeleteRequest(), watchConnectionAdded()]);
}
