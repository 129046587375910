import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";
import * as MyStorage from "../../helpers/myStorage";

export const loginAsync = createAsyncThunk(
  "user/login",
  async (credentials, { rejectWithValue, dispatch }) => {
    try {
      let { connectionCode } = credentials;
      const { jwt, connectionList } = await AuthService.doLogin(credentials);

      MyStorage.setNodeList(connectionList[0].nodeList);
      MyStorage.setToken(jwt);
      MyStorage.setCode(connectionCode);
      const { exp, user } = MyStorage.getTokenInfo();

      return {
        token: jwt,
        expireIn: exp,
        user: { ...user, connectionCode: connectionCode },
      };
    } catch (err) {
      switch (err.code) {
        case 1302:
          return rejectWithValue("User Not Found");
        case 1301:
          return rejectWithValue("Username or password is incorrect");
        case 5002:
          return rejectWithValue("Record not found!");
        default:
          return rejectWithValue(err.error.message.toString());
      }
    }
  }
);

export const logoutAsync = createAsyncThunk(
  "user/logout",
  async (isSessionExpired = null) => {
    try {
      //await AuthService.doLogout();
      MyStorage.removeToken();
    } catch (err) {
      //
    }
    return isSessionExpired;
  }
);

const { token, user, exp } = MyStorage.getTokenInfo();
const initialState = {
  loading: false,
  isLoggedIn: user && Object.keys(user).length !== 0 && MyStorage.getNode(),
  userInfo: user,
  accessToken: token,
  expireIn: exp,
  sessionExpired: true,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginError(state, action) {
      state.error = action.payload;
    },
    removeError(state) {
      state.error = null;
    },
    setExpired(state, action) {
      state.sessionExpired = action.payload || false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        let { token, user, expireIn } = action.payload;
        state.loading = false;
        // state.isLoggedIn = true;
        state.userInfo = user;
        state.accessToken = token;
        state.expireIn = expireIn;
        state.sessionExpired = null;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(logoutAsync.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.userInfo = {};
        state.accessToken = null;
        state.expireIn = 0;
        state.sessionExpired = action.payload;
      });
  },
});

export const { loginError, removeError, setExpired } = authSlice.actions;
export default authSlice.reducer;
