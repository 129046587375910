import React from "react";
import DarkLightMode from "../../components/DarkLightMode";
import LanguageSwitch from "../../components/LanguageSwitch";
import MaxMinWindow from "../../components/MaxMinWindow";
import SidebarToggle from "./SidebarToggle";
import UserAccount from "./UserAccount";
import { useLocation } from "react-router-dom";

const Header = ({ toggleMenu }) => {
  const location = useLocation();
  return (
    <nav
      className="z-50 flex flex-row flex-nowrap items-center justify-between mt-0 py-2 px-6 bg-gray-300 dark:bg-gray-700 shadow-sm transition-all duration-500 ease-in-out ltr:left-0 ltr:right-0 md:ltr:left-64 rtl:right-0 rtl:left-0 md:rtl:right-64 data-[expanded=true]:ltr:left-64 data-[expanded=true]:ltr:-right-64 data-[expanded=true]:md:ltr:left-0 data-[expanded=true]:md:ltr:right-0 data-[expanded=true]:rtl:right-64 data-[expanded=true]:rtl:-left-64 data-[expanded=true]:md:rtl:right-0 aria-expanded:md:rtl:left-0"
      data-expanded={toggleMenu}
    >
      {/* sidenav button*/}
      {!location.pathname.includes("/tvDashboard") && <SidebarToggle />}
      {/* menu */}
      <ul className="flex ltr:ml-auto rtl:mr-auto">
        {/* Customizer (Only for demo purpose) */}
        <li className="relative cursor-pointer">
          <MaxMinWindow />
        </li>
        <li className="relative cursor-pointer">
          <DarkLightMode />
        </li>
        <li className="relative cursor-pointer">
          <LanguageSwitch />
        </li>
        {/* profile */}
        <li className="relative cursor-pointer">
          <UserAccount />
        </li>
      </ul>
    </nav>
  );
};

export default Header;
