import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DarkLightIcon from "../icons-svg/DarkLightIcon";
import { themeMode, toggleMode } from "../features/theme/themeSlice";

const DarkLightMode = () => {
  const { mode } = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    document.documentElement.className = mode;
    localStorage.setItem("thememode", mode);
  }, [mode]);

  const onModeClick = (e) => {
    e.stopPropagation();
    dispatch(toggleMode());
  };

  return (
    <button
      className="cursor-pointer py-1 px-1 flex text-lg rounded-full focus:outline-none"
      onClick={onModeClick}
    >
      {mode === themeMode.DARK ? (
        <DarkLightIcon className="dark w-4 h-4 text-white" />
      ) : (
        <DarkLightIcon className="light w-4 h-4 text-black rotate-180" />
      )}
    </button>
  );
};

export default DarkLightMode;
