import React, { useEffect, useRef, useState } from "react";
import Alert from "./Alert";

const TimerAlert = ({ message, type, delay, fallbackFn }) => {
  const tm = useRef(null);
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (tm.current) {
      clearTimeout(tm.current);
    }
    tm.current = setTimeout(() => {
      fallbackFn();
      setShow(false);
    }, delay);
    return () => {
      clearTimeout(tm.current);
    };
    // eslint-disable-next-line
  }, []);

  const onTimerClose = (e) => {
    fallbackFn();
    clearTimeout(tm.current);
    setShow(false);
  };

  return show ? (
    <Alert message={message} type={type} onClose={onTimerClose} />
  ) : null;
};

TimerAlert.defaultProps = {
  fallbackFn: () => false,
  delay: 1000,
};

export default TimerAlert;
