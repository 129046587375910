import React from "react";
import { Link } from "react-router-dom";
import { LogoIcon } from "../../icons-svg";

const Logo = ({ noAuth = true }) => {
  return (
    <div
      data-auth={noAuth}
      className="mh-18 py-1.5 data-[auth=true]:bg-gray-300 dark:bg-gray-700 data-[auth=true]:shadow-sm"
    >
      <Link to="/" className="relative">
        <h2 className="text-2xl font-semibold text-gray-200 px-4 max-h-9 overflow-hidden hidden-compact">
          <LogoIcon className="inline-block w-28 h-auto ltr:mr-2 rtl:ml-2 -mt-1" />
        </h2>
        <h2 className="text-3xl font-semibold mx-auto logo-compact hidden"></h2>
      </Link>
    </div>
  );
};

export default Logo;
