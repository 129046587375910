import React from "react";
import { BiPlusMedical } from "react-icons/bi";

const TabAddButton = (props) => {
  return (
    <button
      {...props}
      type="button"
      className="disabled:cursor-not-allowed py-1 px-2 inline-block text-center leading-normal text-sky-400 bg-transparent focus:outline-none focus:ring-0"
    >
      <BiPlusMedical className="h-5 w-5" />
    </button>
  );
};

export default TabAddButton;
