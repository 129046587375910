import { takeLatest, all, put } from "redux-saga/effects";

import { loginAsync } from "../../features/auth/authSlice";
import {
  serviceRequest,
  serviceSuccess,
  serviceFailed,
} from "../../features/services/serviceSlice";
import { setLoader, unsetLoader } from "../../features/loader/loaderSlice";
import {
  connectioInitiate,
  connectionFailed,
  connectionSuccess,
  deleteFailed,
  deleteInitiate,
  deleteSuccess,
} from "../../features/connection/connectionSlice";
import {
  initialForgetRequest,
  onForgetSuccess,
  onForgetFailed,
} from "../../features/forget-password/forgetPasswordSlice";
import {
  initiateResetPassword,
  resetPasswordFailed,
  resetPasswordSuccess,
} from "../../features/users/userSlice";
import {
  fetchUserData,
  deleteUser,
  userFetchSuccess,
  userFetchFailed,
  deleteUserFailed,
  deleteUserSuccess,
} from "../../features/users/userSlice";
import {
  COMMON_PROCESSING_MESSAGE,
  INITIATE_PASSWORD_RESET_MAIL,
  APP_INITIALIZE_MESSAGE,
  COMMON_FETCHING_MESSAGE,
  DELETE_MESSAGE,
} from "../../data/message";
import {
  TvDasboardFailed,
  TvDasboardInitiate,
  TvDasboardSuccess,
} from "../../features/TvDasboard/tvDashboardSlice";

//Remove Loader
function* removeLoader() {
  yield put({ type: unsetLoader.toString() });
}

//Show Loader with customized text
function* showLoader(action) {
  let text = COMMON_FETCHING_MESSAGE;
  if (action.type === serviceRequest.toString()) {
    text = APP_INITIALIZE_MESSAGE;
  }
  if (action.type === loginAsync.pending.toString()) {
    text = COMMON_PROCESSING_MESSAGE;
  }
  if (action.type === initiateResetPassword.toString()) {
    text = INITIATE_PASSWORD_RESET_MAIL;
  }
  if (
    action.type === deleteUser.toString() ||
    action.type === deleteInitiate.toString()
  ) {
    text = DELETE_MESSAGE;
  }
  yield put({ type: setLoader.toString(), payload: text });
}

//Keep Listener for API Pending request and show loader
function* watchPending() {
  yield takeLatest(
    [
      serviceRequest.toString(),
      loginAsync.pending.toString(),
      connectioInitiate.toString(),
      deleteInitiate.toString(),
      initialForgetRequest.toString(),
      initiateResetPassword.toString(),
      fetchUserData.toString(),
      deleteUser.toString(),
      TvDasboardInitiate.toString(),
    ],
    showLoader,
  );
}

//Keep Listener for API Success response and hide loader
function* watchSuccess() {
  yield takeLatest(
    [
      loginAsync.fulfilled.toString(),
      serviceSuccess.toString(),
      connectionSuccess.toString(),
      deleteSuccess.toString(),
      onForgetSuccess.toString(),
      resetPasswordSuccess.toString(),
      userFetchSuccess.toString(),
      deleteUserSuccess.toString(),
      TvDasboardSuccess.toString(),
    ],
    removeLoader,
  );
}

//Keep Listener for API Failure response and hide loader
function* watchFailed() {
  yield takeLatest(
    [
      loginAsync.rejected.toString(),
      serviceFailed.toString(),
      connectionFailed.toString(),
      deleteFailed.toString(),
      onForgetFailed.toString(),
      resetPasswordFailed.toString(),
      userFetchFailed.toString(),
      deleteUserFailed.toString(),
      TvDasboardFailed.toString(),
    ],
    removeLoader,
  );
}

export default function* loaderSaga() {
  yield all([watchPending(), watchSuccess(), watchFailed()]);
}
