import { useTranslation } from "react-i18next";
import * as MyStorage from "../helpers/myStorage";
import { getServiceList } from "../services/service.service";
import { useEffect } from "react";
const NodeModal = () => {
  const { t } = useTranslation();
  useEffect(() => {
    if (MyStorage.getNodeList().length === 1) {
      MyStorage.setNode(MyStorage.getNodeList()[0].code);
      getServiceList({
        connectionCode: MyStorage.getCode(),
        nodeCode: MyStorage.getNode(),
      }).then((res) => {
        // MyStorage.setTokenForMail(res[0]?.jwt);
        // MyStorage.setLinkForMail(res[0]?.urlList[0]?.address);
        res.forEach((e) => {
          if (e?.code == 11) {
            MyStorage.setServiceToken(e?.jwt);
          } else if (e?.code == 560) {
            MyStorage.setTokenForTvDashboard(e?.jwt);
            MyStorage.setLinkForTvDashboard(e?.urlList[0]?.address);
          }
        });
        window.location.reload();
      });
    }
  }, []);
  const selectNode = async (node) => {
    MyStorage.setNode(node.code);
    const res = await getServiceList({
      connectionCode: MyStorage.getCode(),
      nodeCode: MyStorage.getNode(),
    });
    res.forEach((e) => {
      if (e?.code == 11) {
        MyStorage.setServiceToken(e?.jwt);
      } else if (e?.code == 560) {
        MyStorage.setTokenForTvDashboard(e?.jwt);
        MyStorage.setLinkForTvDashboard(e?.urlList[0]?.address);
      }
    });

    // MyStorage.setTokenForMail(res[0]?.jwt);
    // MyStorage.setLinkForMail(res[0]?.urlList[0]?.address);

    window.location.reload();
  };
  return (
    <div
      tabIndex="-1"
      className=" top-0 left-0 z-50 w-full h-full p-4 overflow-x-hidden justify-center flex items-center overflow-y-auto md:inset-0 absolute  "
    >
      <div className="relative w-full max-w-2xl max-h-full">
        <div className="relative bg-white rounded-lg shadow-lg border-4 border-indigo-600 dark:bg-gray-900">
          <div className=" w-full p-5 flex justify-center text-lg font-semibold border-b border-indigo-600 ">
            <h1>{t("Select Node")}</h1>
          </div>
          <div className="p-6 space-y-6 w-full flex flex-col items-center justify-center">
            {MyStorage.getNodeList()?.map((node, i) => {
              return (
                <div
                  className=" text-base font-medium flex justify-center hover:bg-indigo-600 hover:text-white w-full py-2 cursor-pointer"
                  key={i}
                  onClick={() => selectNode(node)}
                >
                  {node.name} / {node.code}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NodeModal;
