import React from "react";
import WhiteInputText from "./WhiteInputText";
import InputError from "../InputError";
import Sup from "../Sup";

const InputWithLableAndError = (props) => {
  const { title, name, error, ...p } = props;
  return (
    <div className="form-group flex-shrink max-w-full px-4 w-full md:w-1/2 mb-2">
      <label
        htmlFor={name}
        className="inline-block text-gray-400 dark:text-gray-300 mb-2"
      >
        {title} {props.required ? <Sup /> : null}
      </label>
      <WhiteInputText {...p} name={name} autoComplete="off" />
      <InputError>{error}</InputError>
    </div>
  );
};

export default InputWithLableAndError;
