import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { reset, close } from "../../features/toast/toastSlice";

//Dynamically Attached component in Tab
const importView = (componentPath) =>
  React.lazy(() =>
    import(`./${componentPath}`).catch(() => import(`./InfoToast`))
  );

const Toast = () => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [toastsView, setToastView] = useState(null);
  const { toasts } = useSelector((state) => state.toast);

  useEffect(() => {
    setLoaded(true);
    return () => {
      setLoaded(false);
      dispatch(reset());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function loadViews() {
      const componentPromises = toasts.map(
        async ({ id, component, message, ...p }) => {
          const View = await importView(component);
          return (
            <View key={id} id={id} {...p} close={() => dispatch(close(id))}>
              {message}
            </View>
          );
        }
      );

      Promise.all(componentPromises).then(setToastView);
    }

    loadViews();
    // eslint-disable-next-line
  }, [toasts]);

  return loaded
    ? createPortal(
        <React.Suspense>
          {toastsView?.length > 0 && (
            <div
              className={`md:w-[430px] w-full py-6 px-2 flex-col flex fixed top-5 right-0`}
            >
              {toastsView}
            </div>
          )}
        </React.Suspense>,
        document.getElementById("toast-root")
      )
    : null;
};

export default Toast;
