import React from "react";
import { GoGistSecret } from "react-icons/go";

const TabSecretButton = (props) => {
  return (
    <button
      {...props}
      type="button"
      className="disabled:cursor-not-allowed py-2 px-2 inline-block text-center  leading-normal text-yellow-500 bg-transparent border dark:border-gray-700 border-gray-200 hover:text-gray-100 hover:bg-yellow-600 hover:ring-0 hover:border-yello-600 focus:text-gray-100 focus:bg-yellow-600 focus:border-yellow-600 focus:outline-none focus:ring-0"
    >
      <GoGistSecret className="h-4 w-4" />
    </button>
  );
};

export default TabSecretButton;
