import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  loadingText: "Please wait. Loading...",
};
const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setLoader(state, action) {
      state.loading = true;
      if (action.payload) {
        state.loadingText = action.payload;
      }
    },
    unsetLoader(state) {
      state.loading = false;
      state.loadingText = "Please wait. Loading...";
    },
  },
});

export const { setLoader, unsetLoader } = loaderSlice.actions;
export default loaderSlice.reducer;
