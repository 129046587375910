import React, { useState } from "react";
import { TbArrowsMaximize, TbArrowsMinimize } from "react-icons/tb";

function openFullscreen() {
  if (document.documentElement.requestFullscreen) {
    document.documentElement.requestFullscreen();
  } else if (document.documentElement.webkitRequestFullscreen) {
    /* Safari */
    document.documentElement.webkitRequestFullscreen();
  } else if (document.documentElement.msRequestFullscreen) {
    /* IE11 */
    document.documentElement.msRequestFullscreen();
  }
}

/* Close fullscreen */
function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    /* Safari */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE11 */
    document.msExitFullscreen();
  }
}

const MaxMinWindow = () => {
  const [maximize, setMaximize] = useState(false);

  const onModeClick = (e) => {
    e.stopPropagation();
    if (!maximize) {
      openFullscreen();
      setMaximize(true);
    } else {
      closeFullscreen();
      setMaximize(false);
    }
  };

  return (
    <button
      className="cursor-pointer py-1 px-1 flex text-lg rounded-full text-yellow-600 focus:outline-none"
      onClick={onModeClick}
    >
      {maximize ? (
        <TbArrowsMinimize className="block w-4 h-4" />
      ) : (
        <TbArrowsMaximize className="block w-4 h-4" />
      )}
    </button>
  );
};

export default MaxMinWindow;
