import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: {},
  data: [],
  selectedRecords: {},
  deleting: false,
  loading: false,
  error: null,
  total: 0,
  pageCount: 0,
  queryData: {
    filter: {},
    pageIndex: 1,
    pageSize: 10,
    language: "en",
  },
};

const tvDashboardSlice = createSlice({
  name: "Dashboard",
  initialState,
  reducers: {
    TvDasboardRemoveError(state) {
      state.error = null;
    },
    TvDasboardSyncFormData(state, action) {
      state.formData = action.payload;
    },
    TvDasboardOnFilterSearch(state, action) {
      let filter = action.payload;
      state.queryData = {
        ...state.queryData,
        filter: filter,
        pageIndex: 1,
      };
    },
    TvDasboardPageSizeChange(state, action) {
      state.queryData = {
        ...state.queryData,
        pageSize: action.payload,
        pageIndex: 1,
      };
    },
    TvDasboardPageChange(state, action) {
      state.queryData = {
        ...state.queryData,
        pageIndex: action.payload,
      };
    },
    TvDasboardOnRowSelection(state, action) {
      state.selectedRecords = action.payload;
    },
    TvDasboardUpdateRowAfterUpdate(state, action) {
      let newItem = action.payload;
      state.data = state.data.map((item) => {
        if (item.id === newItem.id) {
          return { ...item, ...newItem };
        }
        return item;
      });
    },
    TvDasboardSuccess(state, action) {
      let { pageSize } = state.queryData;
      let { data, total } = action.payload;
      let pageCount = Math.ceil(total / pageSize);
      state.data = data;
      state.total = total;
      state.pageCount = pageCount;
      state.loading = false;
    },
    TvDasboardInitiate(state) {
      state.loading = true;
      state.selectedRecords = {};
    },
    TvDasboardFailed(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    TvDasboardDeleteSuccess(state, action) {
      let uuid = action.payload;
      state.data = [...state.data.filter((item) => item.uuid !== uuid)];
      state.deleting = false;
    },
    TvDasboardDeleteInitiate(state) {
      state.deleting = true;
    },
    TvDasboardDeleteFailed(state, action) {
      state.error = action.payload;
      state.deleting = false;
    },
    resetTvDasboardData(state) {
      state.data = [];
      state.selectedRecords = {};
      state.total = 0;
      state.pageCount = 0;
      state.queryData = {
        filter: {},
        pageIndex: 1,
        pageSize: 10,
      };
    },
  },
});

export const TvDasboardAdded = createAction("tvDasboard/added");

export const {
  TvDasboardRemoveError,
  TvDasboardSyncFormData,
  TvDasboardOnFilterSearch,
  TvDasboardPageSizeChange,
  TvDasboardPageChange,
  TvDasboardOnRowSelection,
  TvDasboardUpdateRowAfterUpdate,
  TvDasboardSuccess,
  TvDasboardInitiate,
  TvDasboardFailed,
  TvDasboardDeleteSuccess,
  TvDasboardDeleteInitiate,
  TvDasboardDeleteFailed,
  resetTvDasboardData,
} = tvDashboardSlice.actions;

export default tvDashboardSlice.reducer;
