import { createSlice } from "@reduxjs/toolkit";
import { generateUEID } from "../../helpers/utitity";

const initialState = {
  toasts: [],
};
const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    open(state, action) {
      let defaultCoponent = { component: "InfoToast" };
      state.toasts = [
        ...state.toasts,
        { ...defaultCoponent, ...action.payload, id: generateUEID() },
      ];
    },
    error(state, action) {
      state.toasts = [
        ...state.toasts,
        { ...action.payload, id: generateUEID(), component: "ErrorToast" },
      ];
    },
    success(state, action) {
      state.toasts = [
        ...state.toasts,
        { ...action.payload, id: generateUEID(), component: "SuccessToast" },
      ];
    },
    warning(state, action) {
      state.toasts = [
        ...state.toasts,
        { ...action.payload, id: generateUEID(), component: "WarningToast" },
      ];
    },
    info(state, action) {
      state.toasts = [
        ...state.toasts,
        { ...action.payload, id: generateUEID(), component: "InfoToast" },
      ];
    },
    close(state, action) {
      state.toasts = [
        ...state.toasts.filter((toast) => toast.id !== action.payload),
      ];
    },
    reset(state) {
      state.toasts = [];
    },
  },
});

export const { open, info, error, success, warning, reset, close } =
  toastSlice.actions;
export default toastSlice.reducer;
