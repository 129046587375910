import { createSlice } from "@reduxjs/toolkit";
import { getServiceToken } from "../../helpers/myStorage";

const initialState = {
  loading: false,
  urlList: [],
  permissions: null,
  serviceToken: getServiceToken(),
  error: null,
  success: null,
};

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    serviceRequest(state) {
      state.loading = true;
    },
    serviceSuccess(state, action) {
      let { token, urlList, permissions } = action.payload;
      state.loading = false;
      state.urlList = urlList;
      state.serviceToken = token;
      state.permissions = permissions;
      state.success = true;
    },
    serviceFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { serviceRequest, serviceSuccess, serviceFailed } =
  serviceSlice.actions;
export default serviceSlice.reducer;
