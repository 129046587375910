import axios from "axios";
import { renewToken } from "../services/auth.service";
import * as MyStorage from "./myStorage";
const ApiUrl = process.env.REACT_APP_API_URL;

/**
 * Setup a axios intsance for whole application
 * Here we setup basic config like API url and request headers
 */
const axiosInstance = axios.create({
  baseURL: ApiUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const axiosPublicInstance = axios.create({
  baseURL: ApiUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const axiosInstanceForMail = axios.create({
  // baseURL: "https://mailtest.akead.link",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Jwt: MyStorage.getLinkForMail(),
  },
});

export const axiosInstanceForTvDashboard = axios.create({
  baseURL: MyStorage.getLinkForTvDashboard(),
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    jwt: MyStorage.getAccessToken() + "." + MyStorage.getTokenForTvDashboard(),
  },
});

/**
 * Here we can handle API response and can modify
 * before using it in recat application or component
 *
 * @param {API Response} response
 * @returns Promise
 */
const handleResponse = (response) => {
  return new Promise(async (resolve) => {
    const resData = response.data;
    return resolve(resData);
  });
};

/**
 * Here we can handle API response status code and error
 * We can customized Error for our application
 *
 * @param {Object} data
 * @returns
 */

/**
 * This function is used to handle faild API response
 * Here we can refresh token
 *
 * @param {Object} error
 * @returns
 */
const handleErrorResponse = async (error) => {
  // eslint-disable-next-line
  const { config, response } = error;
  const resData = response.data;
  if (response.status === 400 && resData.error.message === "Expired Jwt") {
    MyStorage.removeToken();
    window.location.href = "/authentication";
    return Promise.reject("Session Expired");
  }
  return Promise.reject(resData);
};

const handlePublicErrorResponse = async (error) => {
  // eslint-disable-next-line
  const { config, response } = error;
  const resData = response.data;
  return Promise.reject(resData);
};

/**
 * We can intercept the outgoing Rest API request data here
 * We can inlcude headers or can modify request body
 *
 * @param {Request} req
 * @returns
 */
const handleRequest = async (req) => {
  let { exp } = MyStorage.getTokenInfo();
  if (
    MyStorage.shouldTokenRenew(exp) &&
    req.url !== "/Membership/login" &&
    req.url !== "/Membership/renewToken" &&
    req.url !== "/Membership/renewPasswordMail"
  ) {
    await renewToken({ oldJwt: MyStorage.getAccessToken() }).then((res) => {
      MyStorage.setToken(res?.jwt);
      setTimeout(() => {
        const channel = new BroadcastChannel("myChannel");
        channel.postMessage("new token");
      }, 1000);
    });
  }

  let headers = req.headers;
  if (
    req.url.includes("Membership") &&
    req.url !== "/Membership/login" &&
    req.url !== "/Membership/renewToken" &&
    req.url !== "/Membership/renewPasswordMail"
  ) {
    headers["application-jwt"] = MyStorage.getAccessToken();
  }
  // if (req.url.includes("https://mailtest.akead.link/")) {
  //   headers[
  //     "jwt"
  //   ] = `${MyStorage.getAccessToken()}.${MyStorage.getTokenForMail()}`;
  // }
  if (req.url.includes("https://dashbordtvtest.akead.link")) {
    headers[
      "jwt"
    ] = `${MyStorage.getAccessToken()}.${MyStorage.getTokenForTvDashboard()}`;
    headers["connection-code"] = MyStorage.getCode();
  }
  if (req.url.includes("Admin")) {
    headers[
      "jwt"
    ] = `${MyStorage.getAccessToken()}.${MyStorage.getServiceToken()}`;
  }

  req.headers = headers;
  return req;
};

//Handle request error
const handleRequestError = (error) => {
  return Promise.reject(error);
};

//Request Interceptor
axiosInstance.interceptors.request.use(handleRequest, handleRequestError);

//Response Interceptor
axiosInstance.interceptors.response.use(handleResponse, handleErrorResponse);

axiosInstanceForMail.interceptors.request.use(
  handleRequest,
  handleRequestError
);

axiosInstanceForMail.interceptors.response.use(
  handleResponse,
  handleErrorResponse
);

axiosInstanceForTvDashboard.interceptors.request.use(
  handleRequest,
  handleRequestError
);

axiosInstanceForTvDashboard.interceptors.response.use(
  handleResponse,
  handleErrorResponse
);

axiosPublicInstance.interceptors.request.use((req) => {
  let token = MyStorage.getAccessToken();
  let headers = req.headers;
  if (
    req.url.includes("Membership") &&
    req.url !== "/Membership/login" &&
    req.url !== "/Membership/renewToken" &&
    req.url !== "/Membership/renewPasswordMail"
  ) {
    headers["application-jwt"] = token;
  }
  return req;
}, handleRequestError);

axiosPublicInstance.interceptors.response.use(
  handleResponse,
  handlePublicErrorResponse
);

export default axiosInstance;
