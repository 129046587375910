import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import DarkLightMode from "../../components/DarkLightMode";
import LanguageSwitch from "../../components/LanguageSwitch";
import MaxMinWindow from "../../components/MaxMinWindow";
import Logo from "./Logo";

const AuthLayout = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  if (isLoggedIn) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <div className="h-screen">
      <header className="bg-gray-200 dark:bg-gray-700 z-50">
        <nav className="flex flex-row flex-nowrap items-center justify-between mt-0 py-1 px-6 ">
          <Logo noAuth={false} />
          <ul className="flex ltr:ml-auto rtl:mr-auto">
            <li className="relative  z-50">
              <MaxMinWindow />
            </li>
            <li className="relative  z-50">
              <DarkLightMode />
            </li>
            <li className="relative  z-50">
              <LanguageSwitch />
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <div className="relative py-12">
          <div className="container xl:max-w-6xl mx-auto px-4">
            <div className="flex flex-wrap flex-row -mx-4 justify-center">
              <Outlet />
            </div>
          </div>
        </div>
      </main>
      <footer className="bg-transparent"></footer>
    </div>
  );
};

export default AuthLayout;
