import React from "react";

const CheckboxInput = (props) => {
  return (
    <input
      {...props}
      className="align-middle inline-block flex-shrink-0 text-indigo-500 dark:bg-gray-700 border border-gray-300 dark:border-gray-700 rounded focus:outline-none"
      type="checkbox"
    />
  );
};

export default CheckboxInput;
