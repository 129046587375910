import { all } from "redux-saga/effects";
import loaderSaga from "./loader/loaderSaga";
import connectionSaga from "./connection/connectionSaga";
import forgetPasswordSaga from "./forget-password/forgetPasswordSaga";
import tabSaga from "./tabs/tagSaga";
import userSearchSaga from "./user-search/userSearchSaga";
import mailSaga from "./mail/mail";
import tvDashboardSaga from "./tvDashboard/tvDashboardSaga";
import deviceSaga from "./device/deviceSaga";

export default function* rootSaga() {
  yield all([
    loaderSaga(),
    connectionSaga(),
    forgetPasswordSaga(),
    tabSaga(),
    userSearchSaga(),
    mailSaga(),
    tvDashboardSaga(),
    deviceSaga(),
  ]);
}
