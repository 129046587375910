import { cloneElement } from "react";
import PropTypes from "prop-types";

const IconForButton = ({ children }) => {
  return cloneElement(children, {
    className:
      "inline-block w-4 h-4 ltr:mr-2 rtl:ml-2 bi bi-box-arrow-in-right",
  });
};
IconForButton.propTypes = {
  children: PropTypes.element.isRequired,
};

export default IconForButton;
