import { createSlice, createAction } from "@reduxjs/toolkit";

const initialState = {
  formData: {},
  data: [],
  selectedRecords: {},
  deleting: false,
  loading: false,
  error: null,
  total: 0,
  pageCount: 0,
  queryData: {
    filter: {},
    pageIndex: 1,
    pageSize: 10,
  },
};

const connectionSlice = createSlice({
  name: "connection",
  initialState,
  reducers: {
    removeError(state) {
      state.error = null;
    },
    syncFormData(state, action) {
      state.formData = action.payload;
    },
    onFilterSearch(state, action) {
      let filter = action.payload;
      state.queryData = {
        ...state.queryData,
        filter: filter,
        pageIndex: 1,
      };
    },
    pageSizeChange(state, action) {
      state.queryData = {
        ...state.queryData,
        pageSize: action.payload,
        pageIndex: 1,
      };
    },
    pageChange(state, action) {
      state.queryData = {
        ...state.queryData,
        pageIndex: action.payload,
      };
    },
    onRowSelection(state, action) {
      state.selectedRecords = action.payload;
    },
    updateRowAfterUpdate(state, action) {
      let newItem = action.payload;
      state.data = state.data.map((item) => {
        if (item.id === newItem.id) {
          return { ...item, ...newItem };
        }
        return item;
      });
    },
    connectionSuccess(state, action) {
      let { pageSize } = state.queryData;
      let { data, total } = action.payload;
      let pageCount = Math.ceil(total / pageSize);
      state.data = data;
      state.total = total;
      state.pageCount = pageCount;
      state.loading = false;
    },
    connectioInitiate(state) {
      state.loading = true;
      state.selectedRecords = {};
    },
    connectionFailed(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    deleteSuccess(state, action) {
      let id = action.payload;
      state.data = [...state.data.filter((item) => item.id !== id)];
      state.deleting = false;
    },
    deleteInitiate(state) {
      state.deleting = true;
    },
    deleteFailed(state, action) {
      state.error = action.payload;
      state.deleting = false;
    },
    resetConnectionData(state) {
      state.data = [];
      state.selectedRecords = {};
      state.total = 0;
      state.pageCount = 0;
      state.queryData = {
        filter: {},
        pageIndex: 1,
        pageSize: 10,
      };
    },
  },
});

export const connectionAdded = createAction("connection/added");
export const connectionUpdate = createAction("connection/updated");

export const {
  removeError,
  syncFormData,
  onFilterSearch,
  pageSizeChange,
  pageChange,
  onRowSelection,
  connectionSuccess,
  connectioInitiate,
  connectionFailed,
  deleteInitiate,
  deleteFailed,
  deleteSuccess,
  updateRowAfterUpdate,
  resetConnectionData,
} = connectionSlice.actions;

export default connectionSlice.reducer;
