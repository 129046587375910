import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
};
const forgetPasswordSlice = createSlice({
  name: "forget-password",
  initialState,
  reducers: {
    removeMessage(state, action) {
      if (action.payload === "success") {
        state.success = null;
      } else {
        state.error = null;
      }
    },
    initialForgetRequest(state) {
      state.loading = true;
    },
    onForgetSuccess(state) {
      state.loading = false;
      state.success = true;
    },
    onForgetFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  removeMessage,
  initialForgetRequest,
  onForgetSuccess,
  onForgetFailed,
} = forgetPasswordSlice.actions;
export default forgetPasswordSlice.reducer;
