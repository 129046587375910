import React from "react";
import { useDispatch } from "react-redux";
import { SwitchIcon } from "../../icons-svg";
import { removeTab } from "../../features/tabs/tabSlice";

const TabActionButtons = ({ tabId, children, fallbackFn }) => {
  const dispatch = useDispatch();

  const onClick = (e) => {
    fallbackFn();
    dispatch(removeTab(tabId));
  };
  return (
    <div
      className="py-0.5 inline-flex w-full dark:bg-gray-700 bg-gray-200 border-t border-b border-indigo-500 border-opacity-40 dark:border-indigo-500 dark:border-opacity-40"
      role="group"
    >
      <button
        onClick={onClick}
        type="button"
        className="py-2 px-2 inline-block text-center leading-normal text-red-500 bg-transparent focus:outline-none focus:ring-0"
      >
        <SwitchIcon className="h-4 w-4" />
      </button>
      {children}
    </div>
  );
};

TabActionButtons.defaultProps = {
  fallbackFn: () => false,
};

export default TabActionButtons;
