import { takeLatest, all, put, call, select } from "redux-saga/effects";
import {
  DeviceDeleteFailed,
  DeviceDeleteInitiate,
  DeviceDeleteSuccess,
  DeviceFailed,
  DeviceInitiate,
  DeviceSuccess,
} from "../../features/device/DeviceSlice";
import DeviceService from "../../services/device.service";
import { DELETED } from "../../data/message";
import { success } from "../../features/toast/toastSlice";

const handleQueryData = (queryData) => {
  let { pageIndex, pageSize, filter } = queryData;
  let offset = (pageIndex - 1) * pageSize;
  let queryString = {};
  queryString["limit"] = pageSize;
  queryString["offset"] = offset;
  for (let key in filter) {
    if (filter[key]) {
      queryString[key] = filter[key];
    }
  }
  return queryString;
};

/**
 * Make Rest API call to get Modal serach data
 * On Success store list data in list redux state
 * On Failed update list redux error state to show error on the Listing Page
 *
 * @param {Object} action
 */

function* fetcDevices(action) {
  try {
    let postData = handleQueryData(action.payload);
    const { deviceList, paging } = yield call(
      DeviceService.getDeviceList,
      postData
    );
    let { totalResults, resultCount } = paging;
    if (deviceList.length > 0) {
      yield put({
        type: DeviceSuccess.toString(),
        payload: {
          data: deviceList,
          total: totalResults,
          resultCount: resultCount,
        },
      });
    } else {
      yield put({
        type: DeviceSuccess.toString(),
        payload: { data: [], total: 0, resultCount: 0 },
      });
    }
  } catch (err) {
    let { message } = err;
    yield put({ type: DeviceFailed.toString(), payload: message });
  }
}

/**
 * Here we initiate a Rest API call to delete model
 * On success or Failed we update model redux data
 *
 * @param {Object} action
 */
function* deleteDevice(action) {
  try {
    let uuid = action.payload;
    yield call(DeviceService.deleteDevice, uuid);
    yield put({
      type: success.toString(),
      payload: { message: DELETED, delay: 5000 },
    });
    yield put({ type: DeviceDeleteSuccess.toString(), payload: uuid });
    yield call(reInitiateDeviceList);
  } catch (err) {
    let { message } = err;
    if (message === "Database Error") {
      message = "Some error occured.";
    }
    yield put({
      type: DeviceFailed.toString(),
      payload: {
        message,
        delay: 5000,
      },
    });
    yield put({ type: DeviceDeleteFailed.toString(), payload: message });
  }
}

function* reInitiateDeviceList() {
  let queryData = yield select((state) => state.device.queryData);
  yield put({ type: DeviceInitiate.toString(), payload: queryData });
}

function* watchDeleteRequest() {
  yield takeLatest(DeviceDeleteInitiate.toString(), deleteDevice);
}

function* watchListRequest() {
  yield takeLatest(DeviceInitiate.toString(), fetcDevices);
}

export default function* tvDashboardSaga() {
  yield all([watchListRequest(), watchDeleteRequest()]);
}
