import React from "react";
import PropTypes from "prop-types";

const InputText = ({ onChange, ...p }) => {
  return (
    <input
      {...p}
      className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 bg-gray-100 border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600 optional:bg-black"
      onChange={onChange}
      autoComplete="off"
    />
  );
};

InputText.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default InputText;
