import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { logoutAsync } from "../features/auth/authSlice";
import { isSessionExpired } from "../helpers/myStorage";

const SessionExpired = () => {
  const sessionListener = useRef(null);
  const dispatch = useDispatch();
  const [expired, setExpired] = useState(false);

  const checkAndClearTimer = () => {
    if (sessionListener.current) {
      clearInterval(sessionListener.current);
    }
  };

  //Polling to check session status
  useEffect(() => {
    if (isSessionExpired()) {
      checkAndClearTimer();
      dispatch(logoutAsync());
    } else {
      checkAndClearTimer();
      sessionListener.current = setInterval(() => {
        if (isSessionExpired()) {
          checkAndClearTimer();
          const channel = new BroadcastChannel("myChannel");
          channel.postMessage("logout");
          setExpired(true);
        }
      }, 10000);
    }

    return () => {
      checkAndClearTimer();
    };

    // eslint-disable-next-line
  }, []);

  const onClick = () => {
    dispatch(logoutAsync());

    return <Navigate to="/authentication" replace={true} />;
  };

  return expired ? (
    <div
      tabIndex="0"
      className="z-50 overflow-auto inset-0 w-full h-full fixed py-6"
    >
      <div className="z-50 relative p-3 mx-auto my-0 max-w-sm transition duration-500">
        <div className="bg-white dark:bg-gray-700 rounded-lg shadow-lg border dark:border-gray-700 flex flex-col overflow-hidden">
          <div className="text-center space-y-2 rounded dark:text-gray-300 text-gray-100 bg-gray-400 border-gray-400 items-center p-2 flex flex-col w-96 dark:bg-indigo-600 border dark:border-indigo-600">
            <span className="w-full">Session Expired</span>
            <div className="w-1/2">
              <button
                className="px-3 py-1 uppercase border bg-gray-300 text-gray-900 border-gray-300 dark:border-indigo-500 rounded dark:bg-indigo-500 dark:text-gray-300"
                onClick={onClick}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="z-40 overflow-auto left-0 top-0 bottom-0 right-0 w-full h-full fixed opacity-50 bg-black dark:opacity-70"></div>
    </div>
  ) : null;
};

export default SessionExpired;
