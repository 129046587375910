import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FillDownArrowIcon from "../../icons-svg/FillDownArrowIcon";
import UserIcon from "../../icons-svg/UserIcon";
import { logoutAsync } from "../../features/auth/authSlice";

const UserAccount = () => {
  const ref = useRef();
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const user = useSelector((state) => state.auth.userInfo);
  const dispatch = useDispatch();
  /**
   * Adding Click Listener on document
   * Close the Dropdown if somebody click outside the dropdown
   */
  useEffect(() => {
    const clickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setToggle(false);
      }
    };

    document.addEventListener("click", clickOutside, true);

    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const toggleOnClick = (e) => {
    e.preventDefault();
    setToggle((oldToggle) => !oldToggle);
  };

  /*
   * Dispatch Logout Action to Call Rest Api
   * Remove all token From the Local Storage
   */
  const onLogout = (e) => {
    dispatch(logoutAsync());
  };

  return (
    <div ref={ref} className="cursor-pointer">
      <button
        onClick={toggleOnClick}
        className="py-1 px-1 flex text-sm rounded-full focus:outline-none dark:text-gray-100"
        id="user-menu-button"
      >
        <div className="relative cursor-pointer">
          <UserIcon className="cursor-pointer h-4 w-4 text-[#069AFF]" />
        </div>
        <span className="hidden md:block ltr:ml-1 rtl:mr-1 self-center cursor-pointer">
          {user?.login}
        </span>
        <span className="hidden md:block pt-1.5 pl-2 cursor-pointer">
          <FillDownArrowIcon className="h-3 w-3" />
        </span>
      </button>
      {toggle && (
        <ul
          className="transition-all duration-200 ease-out origin-top-right absolute ltr:right-0 rtl:left-0 rounded top-full z-[51] py-0.5 ltr:text-left rtl:text-right bg-white dark:bg-gray-800 border dark:border-gray-700 shadow-md"
          style={{ minWidth: "12rem" }}
        >
          <li className="relative">
            <hr className="border-t border-gray-200 dark:border-gray-700 my-0" />
          </li>
          <li
            className="relative cursor-pointer"
            onClick={(e) => setToggle(false)}
          >
            <Link
              to="/profile"
              className="cursor-pointer block w-full py-2 px-6 clear-both whitespace-nowrap hover:text-indigo-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="cursor-pointer inline ltr:mr-2 rtl:ml-2 w-4 h-4 bi bi-gear"
                viewBox="0 0 16 16"
              >
                <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"></path>
                <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"></path>
              </svg>
              {/* <i className="mr-2 fas fa-cog"></i> */} {t(`Profile`)}
            </Link>
          </li>
          <li className="relative">
            <hr className="border-t border-gray-200 dark:border-gray-700 my-0" />
          </li>
          <li className="relative cursor-pointer">
            <button
              type="button cursor-pointer"
              onClick={onLogout}
              className="flex items-center w-full py-2 px-6 clear-both whitespace-nowrap hover:text-indigo-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="cursor-pointer inline ltr:mr-2 rtl:ml-2 w-4 h-4"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"
                ></path>
                <path
                  fillRule="evenodd"
                  d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                ></path>
              </svg>
              &nbsp;{t(`Sign out`)}
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default UserAccount;
