import React from "react";
import { EditIcon } from "../../icons-svg";

const TabEditButton = (props) => {
  return (
    <button
      {...props}
      type="button"
      className="disabled:cursor-not-allowed py-2 px-2 inline-block text-center leading-normal text-green-500 bg-transparent focus:outline-none focus:ring-0"
    >
      <EditIcon className="h-4 w-4" />
    </button>
  );
};

export default TabEditButton;
