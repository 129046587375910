import { createAction, createSlice } from "@reduxjs/toolkit";

export const fetchMailData = createAction("mail/fetch");
export const deleteMail = createAction("mail/delete");
export const mailAdded = createAction("mail/added");
export const mailUpdated = createAction("mail/updated");

const initialState = {
  formData: {},
  data: [],
  selectedMails: {},
  deleting: false,
  loading: false,
  error: null,
  success: null,
  total: 0,
  pageCount: 0,
  queryData: {
    filter: {
      mailStatus: 1,
    },
    pageIndex: 1,
    pageSize: 10,
  },
};

const mailSlice = createSlice({
  name: "mail",
  initialState,
  reducers: {
    removeError(state, action) {
      if (action.payload !== undefined) {
        state.success = null;
      } else {
        state.error = null;
      }
    },
    syncFormData(state, action) {
      state.formData = action.payload;
    },
    onFilterSearch(state, action) {
      let filter = action.payload;
      state.queryData = {
        ...state.queryData,
        filter: filter,
        pageIndex: 1,
      };
    },
    pageCountChange(state, action) {
      state.pageCount = action.payload;
    },
    sortByChange(state, action) {
      state.queryData = {
        ...state.queryData,
        sortBy: action.payload,
        pageIndex: 1,
      };
    },
    pageSizeChange(state, action) {
      state.queryData = {
        ...state.queryData,
        pageSize: action.payload,
        pageIndex: 1,
      };
    },
    pageChange(state, action) {
      state.queryData = {
        ...state.queryData,
        pageIndex: action.payload,
      };
    },
    onMailSelection(state, action) {
      state.selectedMails = action.payload;
    },
    updateRowAfterUpdate(state, action) {
      let newItem = action.payload;
      state.data = state.data.map((item) => {
        if (item.id === newItem.id) {
          return newItem;
        }
        return item;
      });
    },
    mailFetchSuccess(state, action) {
      let { pageSize } = state.queryData;
      let { total, data } = action.payload;
      let pageCount = Math.ceil(total / pageSize);
      state.data = data;
      state.total = total;
      state.pageCount = pageCount;
      state.loading = false;
      state.error = null;
    },
    mailFetchFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
    deleteMailSuccess(state, action) {
      let id = action.payload;
      state.data = state.data.filter((item) => item.id !== id);
      state.deleting = false;
    },
    deleteMailPending(state, action) {
      state.deleting = true;
    },
    deleteMailFailed(state, action) {
      state.deleting = false;
      state.error = action.payload;
    },
    resetMailData(state) {
      state.data = [];
      state.selectedMails = {};
      state.total = 0;
      state.pageCount = 0;
      state.queryData = {
        filter: {
          mailStatus: 1,
        },
        pageIndex: 1,
        pageSize: 10,
      };
    },
  },
});

export const {
  removeError,
  syncFormData,
  onFilterSearch,
  pageCountChange,
  sortByChange,
  pageSizeChange,
  pageChange,
  onMailSelection,
  updateRowAfterUpdate,
  mailFetchSuccess,
  mailFetchFailed,
  deleteMailSuccess,
  deleteMailPending,
  deleteMailFailed,
  resetMailData,
} = mailSlice.actions;

export default mailSlice.reducer;
