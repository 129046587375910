import axiosInstanceForMail from "../helpers/axiosInstance";
import * as MyStorage from "../helpers/myStorage";

export const getAcountList = (data = {}) => {
  return axiosInstanceForMail
    .get(MyStorage.getLinkForMail() + "/accountList", { params: data })
    .then((res) => {
      return res;
    });
};
export const getAccount = (data) => {
  return axiosInstanceForMail.get(MyStorage.getLinkForMail() + "/account", {
    params: data,
  });
};

export const getProviderList = (data = {}) => {
  return axiosInstanceForMail.get(
    MyStorage.getLinkForMail() + "/providerList",
    { params: data },
  );
};
export const getProvider = (data = {}) => {
  return axiosInstanceForMail.get(MyStorage.getLinkForMail() + "/provider", {
    params: data,
  });
};
export const createAccount = (data) => {
  return axiosInstanceForMail.post(
    MyStorage.getLinkForMail() + "/account",
    data,
  );
};

const MailService = {
  getAcountList,
  getProvider,
  getAccount,
  getProviderList,
};
export default MailService;
