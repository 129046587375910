import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { switchLanguage } from "../features/language/languageSlice";

const LanguageSwitch = () => {
  const { language, languages } = useSelector((state) => state.language);
  const dispatch = useDispatch();

  const onLanguageChange = (lang) => {
    dispatch(switchLanguage(lang));
  };

  return (
    <select
      value={language}
      onChange={(e) => onLanguageChange(e.target.value)}
      className="cursor-pointer px-1 font-bold flex bg-transparent text-pink-600 focus:outline-none appearance-none"
    >
      {[...languages]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ code, name }) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
    </select>
  );
};

export default LanguageSwitch;
