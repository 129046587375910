import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: {},
  data: [],
  selectedRecords: {},
  deleting: false,
  loading: false,
  error: null,
  total: 0,
  pageCount: 0,
  queryData: {
    filter: {},
    pageIndex: 1,
    pageSize: 10,
  },
};

const DeviceSlice = createSlice({
  name: "Device",
  initialState,
  reducers: {
    DeviceRemoveError(state) {
      state.error = null;
    },
    DeviceSyncFormData(state, action) {
      state.formData = action.payload;
    },
    DeviceOnFilterSearch(state, action) {
      let filter = action.payload;
      state.queryData = {
        ...state.queryData,
        filter: filter,
        pageIndex: 1,
      };
    },
    DevicePageSizeChange(state, action) {
      state.queryData = {
        ...state.queryData,
        pageSize: action.payload,
        pageIndex: 1,
      };
    },
    DevicePageChange(state, action) {
      state.queryData = {
        ...state.queryData,
        pageIndex: action.payload,
      };
    },
    DeviceOnRowSelection(state, action) {
      state.selectedRecords = action.payload;
    },
    DeviceUpdateRowAfterUpdate(state, action) {
      let newItem = action.payload;
      state.data = state.data.map((item) => {
        if (item.id === newItem.id) {
          return { ...item, ...newItem };
        }
        return item;
      });
    },
    DeviceSuccess(state, action) {
      let { pageSize } = state.queryData;
      let { data, total } = action.payload;
      let pageCount = Math.ceil(total / pageSize);
      state.data = data;
      state.total = total;
      state.pageCount = pageCount;
      state.loading = false;
    },
    DeviceInitiate(state) {
      state.loading = true;
      state.selectedRecords = {};
    },
    DeviceFailed(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    DeviceDeleteSuccess(state, action) {
      let uuid = action.payload;
      state.data = [...state.data.filter((item) => item.uuid !== uuid)];
      state.deleting = false;
    },
    DeviceDeleteInitiate(state) {
      state.deleting = true;
    },
    DeviceDeleteFailed(state, action) {
      state.error = action.payload;
      state.deleting = false;
    },
    resetDeviceData(state) {
      state.data = [];
      state.selectedRecords = {};
      state.total = 0;
      state.pageCount = 0;
      state.queryData = {
        filter: {},
        pageIndex: 1,
        pageSize: 10,
      };
    },
  },
});

// export const DeviceAdded = createAction("Device/added");

export const {
  DeviceRemoveError,
  DeviceSyncFormData,
  DeviceOnFilterSearch,
  DevicePageSizeChange,
  DevicePageChange,
  DeviceOnRowSelection,
  DeviceUpdateRowAfterUpdate,
  DeviceSuccess,
  DeviceInitiate,
  DeviceFailed,
  DeviceDeleteSuccess,
  DeviceDeleteInitiate,
  DeviceDeleteFailed,
  resetDeviceData,
} = DeviceSlice.actions;

export default DeviceSlice.reducer;
