import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  languages: [
    { code: "fr", name: "FR" },
    { code: "en", name: "EN" },
    { code: "tr", name: "TR" },
    { code: "de", name: "DE" },
  ],
  language: "en",
};
const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    switchLanguage(state, action) {
      state.language = action.payload;
    },
  },
});

export const { switchLanguage } = languageSlice.actions;

export default languageSlice.reducer;
