import { takeLatest, all, put, call } from "redux-saga/effects";
import MailService from "../../services/mail.service";
import {
  fetchMailData,
  mailFetchFailed,
  mailFetchSuccess,
  // mailAdded,
  // mailUpdated,
  // mailDeleted,
  // resetMailData,
} from "../../features/mail/mailSlice";
// import { error, success } from "../../features/toast/toastSlice";

const handleQueryData = (queryData) => {
  let {
    // pageIndex,
    // pageSize,
    filter,
  } = queryData;
  // let offset = (pageIndex - 1) * pageSize;
  let queryString = {};
  // queryString['limit'] = pageSize;
  // queryString['offset'] = offset;
  for (let key in filter) {
    if (filter[key]) {
      queryString[key] = filter[key];
    }
  }
  return queryString;
};

/**
 * Make Rest API call to get mail serach data
 * On Success store mail data in mail redux state
 * On Failed update mail redux error state to show error on the Listing Page
 *
 * @param {Object} action
 *
 */

function* fetchMails(action) {
  try {
    let postData = handleQueryData(action.payload);
    const {
      accountList,
      // paging
    } = yield call(MailService.getAcountList, postData);
    if (accountList === null) {
      yield put({
        type: mailFetchFailed.toString(),
        payload: { data: [], total: 0, resultCount: 0 },
      });
      return;
    }
    yield put({
      type: mailFetchSuccess.toString(),
      payload: { data: accountList },
    });
  } catch (err) {
    let {
      // message,
      more,
    } = err;

    yield put({ type: mailFetchFailed.toString(), payload: more });
  }
}

function* watchListRequest() {
  yield takeLatest(fetchMailData.toString(), fetchMails);
}

export default function* mailSaga() {
  yield all([watchListRequest()]);
}
