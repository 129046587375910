import React, { useState, cloneElement } from "react";
import NavList from "./NavList";
import CaretIcon from "../CaretIcon";

const NavItemHeader = ({ item }) => {
  let { title, icon, childs } = item;

  const [expand, setExpand] = useState(false);

  const onExpand = () => {
    setExpand(!expand);
  };

  return (
    <li className="relative">
      <button
        data-open={expand}
        onClick={(e) => onExpand()}
        className="data-[open=true]:text-indigo-500 data-[open=true]:dark:text-gray-300 text-left w-full py-1 px-3 hover:text-indigo-500 dark:hover:text-gray-300"
      >
        <CaretIcon show={childs?.length} isOpen={expand} />
        {icon && cloneElement(icon)}
        <span>{title}</span>
      </button>
      {expand && (
        <NavList
          navList={childs}
          className="block rounded rounded-t-none top-full z-50 ltr:pl-7 rtl:pr-7 py-0.5 ltr:text-left rtl:text-right mb-1 font-normal"
        />
      )}
    </li>
  );
};

export default NavItemHeader;
