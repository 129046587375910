import React, { cloneElement } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NavItemHeader from "./NavItemHeader";
import { openTab } from "../../features/tabs/tabSlice";

const NavItem = ({ item }) => {
  let { id, title, icon, component, childs } = item;
  const { pathname } = useLocation();
  const selectedTab = useSelector((state) => state.tab.selectedTab);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  title = t(title);

  if (childs?.length) {
    return <NavItemHeader item={{ ...item, title }} />;
  }

  const onItemClick = (e) => {
    if (item.tab === false) {
      return;
    }

    dispatch(openTab({ id, title, component }));
    if (pathname !== "/") {
      navigate("/");
    }
  };
  const notClick = (e) => {
    e.preventDefault();
  };
  return (
    <>
      {item?.component?.length > 0 ? (
        <li
          className="relative"
          onClick={title !== "Users" || "TV" ? onItemClick : notClick}
        >
          <button
            data-active={selectedTab === id}
            className="block py-1 px-6 hover:text-indigo-500 dark:text-gray-300 data-[active=true]:dark:text-gray-100 dark:hover:text-gray-100"
            href="#"
          >
            {icon && cloneElement(icon)}
            <span>{title}</span>
          </button>
        </li>
      ) : (
        <li className="relative" onClick={item.onclick}>
          <button
            className="block py-1 px-6 hover:text-indigo-500 dark:text-gray-300 dark:hover:text-gray-100"
            href="#"
          >
            {icon && cloneElement(icon)}
            <span>{title}</span>
          </button>
        </li>
      )}
    </>
  );
};

export default NavItem;
