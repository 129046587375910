import React from "react";
import WhitePasswordInput from "./WhitePasswordInput";
import InputError from "../InputError";
import Sup from "../Sup";

const PasswordInputWithLableAndError = (props) => {
  const { title, name, error, ...p } = props;
  return (
    <div className="form-group flex-shrink max-w-full px-4 w-full md:w-1/2 mb-2">
      <label htmlFor={name} className="inline-block mb-2 dark:text-gray-300">
        {title} {props.required ? <Sup /> : null}
      </label>
      <WhitePasswordInput {...p} name={name} autoComplete="off" />
      <InputError>{error}</InputError>
    </div>
  );
};

export default PasswordInputWithLableAndError;
