import axiosInstance, { axiosPublicInstance } from "../helpers/axiosInstance";
import { CryptoJSAesEncrypt, getDeviceId } from "../helpers/cryp";
import { deviceDetect, isDesktop, deviceType } from "react-device-detect";

const { osName } = deviceDetect();

export const doLogin = (data = {}) => {
  let staticData = {
    appCode: process.env.REACT_APP_CODE,
    deviceUuid: getDeviceId(),
    deviceName: osName === undefined ? "Unknown" : osName,
    deviceType: isDesktop ? "PC" : deviceType,
  };
  let postData = { ...data, ...staticData };

  let signatureString =
    postData["login"] +
    postData["password"] +
    postData["appCode"] +
    postData["deviceUuid"] +
    postData["deviceName"] +
    postData["deviceType"] +
    postData["connectionCode"];

  const SIGNATURE = CryptoJSAesEncrypt(signatureString);
  return axiosInstance.post("/Membership/login", postData, {
    headers: {
      SIGNATURE,
    },
  });
};

export const doLogout = () => {
  return axiosInstance.post("/Membership/logout");
};

export const changePassword = (data) => {
  return axiosInstance.put("/Membership/changePassword", data);
};

export const forgetPassword = (data) => {
  let staticData = {
    appCode: process.env.REACT_APP_CODE,
  };
  let postData = { ...data, ...staticData };

  let signatureString = postData["email"] + postData["appCode"];

  const SIGNATURE = CryptoJSAesEncrypt(signatureString);
  return axiosInstance.post("/Membership/renewPasswordMail", postData, {
    headers: {
      SIGNATURE,
    },
  });
};

export const userProfile = () => {
  return axiosInstance.get("/Membership/userProfile");
};

export const renewToken = (data) => {
  let staticData = {
    appCode: process.env.REACT_APP_CODE,
  };
  let postData = { ...data, ...staticData };
  let signatureString = postData["oldJwt"] + postData["appCode"];
  const SIGNATURE = CryptoJSAesEncrypt(signatureString);
  return axiosPublicInstance.post(`/Membership/renewToken`, postData, {
    headers: {
      SIGNATURE,
    },
  });
};

const AuthService = {
  doLogin,
  forgetPassword,
  changePassword,
  userProfile,
  doLogout,
  renewToken,
};

export default AuthService;
