import { combineReducers } from "redux";
import menuReducer from "./menu/menuSlice";
import themeReducer from "./theme/themeSlice";
import languageReducer from "./language/languageSlice";
import tabReducer from "./tabs/tabSlice";
import userReducer from "./users/userSlice";
import authReducer, { logoutAsync } from "./auth/authSlice";
import serviceReducer from "./services/serviceSlice";
import modalReducer from "./modal/modalSlice";
import loaderReducer from "./loader/loaderSlice";
import connectionReducer from "./connection/connectionSlice";
import forgetPasswordReducer from "./forget-password/forgetPasswordSlice";
import toastReducer from "./toast/toastSlice";
import mailReducer from "./mail/mailSlice";
import dashboardRed from "./TvDasboard/tvDashboardSlice";
import deviceReducer from "./device/DeviceSlice";

const appReducer = combineReducers({
  menu: menuReducer,
  theme: themeReducer,
  language: languageReducer,
  tab: tabReducer,
  user: userReducer,
  connection: connectionReducer,
  auth: authReducer,
  service: serviceReducer,
  modal: modalReducer,
  loader: loaderReducer,
  forgetPassword: forgetPasswordReducer,
  toast: toastReducer,
  mail: mailReducer,
  Dashboard: dashboardRed,
  Device: deviceReducer,
});

const rootReducer = (state, action) => {
  if (action.type === logoutAsync.fulfilled.toString()) {
    state = {
      ...state,
      tab: undefined,
      user: undefined,
      connection: undefined,
      auth: undefined,
      service: undefined,
      modal: undefined,
      toast: undefined,
      mail: undefined,
      Dashboard: undefined,
      Device: undefined,
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
